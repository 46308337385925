import { useState } from "react";

import { Combobox } from "@headlessui/react";
import {
  CheckCircleIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
} from "@heroicons/react/20/solid";
import { classNames } from "../../utils/dom";
import LoadingInline from "../molecules/LoadingInline";

import { Bicycle, useBicycleSearchQuery } from "@app/graphql";
export function BicycleSearch({
  bicycleId,
  bicycleName,
  setBicycleId,
  setBicycleName,
  setYear,
  tradeinOnly = false,
}: {
  bicycleId?: number | undefined | null;
  bicycleName?: string | undefined | null;
  setBicycleId?: (bicycleId: number | null) => void;
  setBicycleName?: (bicycleName: string | null) => void;
  setYear?: (year: number | null) => void;
  tradeinOnly?: boolean;
}) {
  const [query, setQuery] = useState("");

  const { data, loading } = useBicycleSearchQuery({
    variables: {
      q: query,
      tradeinOnly: tradeinOnly, // PIM users need to see all bicycles regardless of tradein status
    },
    skip: query === "" || query.length < 3,
    context: { clientName: "public" },
  });

  const bicycles = data?.bicycleSearch?.nodes || [];

  return (
    <div className="h-8 relative z-10">
      {bicycleId && bicycleName ? (
        <div>
          <div className=" flex items-center justify-start mt-8">
            <CheckCircleIcon className="w-6 h-6 text-sportgreen mr-2 " />
            <div>{bicycleName}</div>

            <button
              className="text-sportgreyweb text-xs ml-2 hover:underline align-baseline"
              onClick={() => {
                if (setBicycleId && setBicycleName) {
                  setQuery(bicycleName);
                  setBicycleId(null);
                  setBicycleName(null);
                }
              }}
            >
              <PencilSquareIcon className="w-4 h-4 inline-block" />
            </button>
          </div>
        </div>
      ) : (
        <div
          className={classNames(
            "mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl bg-white z-10 ring-1 ring-sportblack ring-opacity-30 transition-all",
            bicycles.length > 0 && "shadow-2xl"
          )}
        >
          <Combobox
            onChange={(bicycle: Bicycle) => {
              if (
                bicycle.id &&
                setBicycleId &&
                bicycle.name &&
                setBicycleName
              ) {
                setBicycleId(bicycle.id);
                setBicycleName(bicycle.name);
                setYear && setYear(bicycle.year);
                setQuery(bicycle.name);
              }
            }}
          >
            <div className="relative z-10">
              <MagnifyingGlassIcon
                className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-sportgreyweb"
                aria-hidden="true"
              />
              <Combobox.Input
                className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-sportblack placeholder:text-sportgreyweb focus:ring-0 sm:text-sm"
                placeholder="SØK – Eksempel 2020 Cannondale Trail 4"
                value={query}
                onChange={(event) => setQuery(event.target.value)}
              />
              {loading && <LoadingInline />}
            </div>

            {bicycles.length > 0 && (
              <Combobox.Options
                static
                className="max-h-72 scroll-py-2 overflow-y-auto py-2 text-sm text-sportblack"
              >
                {bicycles.map((bicycle) => (
                  <Combobox.Option
                    key={bicycle.id}
                    value={bicycle}
                    className={({ active }) =>
                      classNames(
                        "cursor-default select-none px-4 py-2 flex gap-x-4 items-center",
                        active && "bg-indigo-600 text-white"
                      )
                    }
                  >
                    <span className="flex-1">{bicycle.name}</span>
                    {bicycle.spokesId && (
                      <span className="flex-0">
                        <div className="bg-gray-50 bg-opacity-80 font-sm font-semibold px-1 m-1 rounded text-blue-500">
                          AI
                        </div>
                      </span>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            )}

            {query !== "" && query.length > 3 && bicycles.length === 0 && (
              <p className="p-4 text-sm text-gray-500">No bicycles found</p>
            )}
          </Combobox>
        </div>
      )}
    </div>
  );
}
