import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { z } from "zod";
import {
  initialFormInventory,
  initialFormInventorySki,
  initialFormInventoryBicycle,
} from "../../lib/form/inventory";
import {
  Inventory,
  InventoryBicycle,
  InventorySki,
  InventoryType,
  ObjectType,
  SkiStiffness,
  InventoryStatus as InventoryStatusEnum,
  SkiType,
  useCreateInventoryImageMutation,
  usePartnerInventoryQuery,
  useRemoveInventoryImageMutation,
  useUpdateInventoryImageOrderMutation,
  useSkiBrandsQuery,
  InventoryImage as InventoryImageType,
} from "@app/graphql/pim";

import { publicWebsite } from "../../config";
import { InventoryStatus } from "../molecules/StatusWorkflow";
import { StickyActionBar } from "../molecules/StickyActionBar";
import { InventoryHeader } from "../molecules/PartnerInventoryHeader";
import ZebraDetailList from "../molecules/ZebraDetailList";
import Loading from "../molecules/Loading";

import { EyeIcon } from "@heroicons/react/24/solid";

import { bicycleTypes } from "@app/config";
import LoadingInline from "../molecules/LoadingInline";
import { Input } from "../atom/input";
import {
  Fieldset,
  Field,
  FieldGroup,
  Legend,
  Label,
  Description,
} from "../atom/fieldset";
import { Textarea } from "../atom/textarea";
import { SelectFromOptions, SelectFromEnum } from "../atom/select";
import { Text } from "../atom/text";
import { Button } from "../atom/button";
import { Switch, SwitchField } from "../atom/switch";
import { BoltIcon } from "@heroicons/react/24/solid";
import { ImageUpload } from "../organisms/ImageUpload";
import { useGetSizes } from "../../pages/internal/inventory/hooks/getFilters";
import BikeNotFound from "../organisms/BikeNotFound";
import useEnum from "../../utils/enum";
import {
  SaveButtons,
  SyncToFinnButton,
} from "../../pages/internal/inventory/InventoryForm/Buttons";

/* eslint-disable react/prop-types */

type StructuredDataType = { [key: string]: any };
type Condition = { key: string; name: string; nullable?: boolean | undefined };

const conditionGeneral: Condition[] = [
  { key: "conditionGeneral", name: "Generell vurdering" },
];

const conditionBicycle: Condition[] = [
  { key: "conditionCosmeticFrame", name: "Bruksmerker og ramme" },
  { key: "conditionSteeringStock", name: "Styrelager" },
  { key: "conditionCrank", name: "Kranklager" },
  { key: "conditionDrive", name: "Drev" },
  { key: "conditionCasette", name: "Kassett" },
  { key: "conditionChain", name: "Kjede" },
  { key: "conditionFrontTire", name: "Dekk foran" },
  { key: "conditionRearTire", name: "Dekk bak" },
  { key: "conditionFrontWheels", name: "Felg foran" },
  { key: "conditionRearWheels", name: "Felg bak" },
  { key: "conditionBrakes", name: "Bremser" },
];

const conditionDampers: Condition[] = [
  { key: "conditionDampersFront", name: "Dempere Foran", nullable: true },
  { key: "conditionDampersBack", name: "Dempere Bak", nullable: true },
];

const conditionMotorBattery: Condition[] = [
  { key: "conditionBattery", name: "Batteri", nullable: true },
  { key: "conditionMotor", name: "Motor", nullable: true },
];

function Conditions({
  data,
  setData,
  conditions,
  errors,
}: {
  data: any;
  setData: any;
  conditions: Condition[];
  errors: Record<string, string>;
}) {
  const values = [
    { label: "God stand", value: 2 },
    { label: "Meget god stand", value: 3 },
    { label: "Nytt", value: 4 },
  ];

  const nullableValues = [
    { label: "Har ikke", value: "" },
    { label: "God stand", value: 2 },
    { label: "Meget god stand", value: 3 },
    { label: "Nytt", value: 4 },
  ];

  const onUpdate = (name: string) => (e: any) => {
    setData({
      ...data,
      [name]: e.target.value === "" ? null : parseFloat(e.target.value),
    });
  };
  return (
    <>
      {conditions.map((item, key) => (
        <Field key={key}>
          <Label>{item.name}</Label>
          <FieldError fieldId={item.key} errors={errors} />
          <SelectFromOptions
            value={data[item.key] === null ? "" : data[item.key]}
            nullable={item.nullable}
            options={item.nullable ? nullableValues : values}
            name={item.key}
            onChange={onUpdate(item.key)}
          />
        </Field>
      ))}
    </>
  );
}

function FieldError({
  fieldId,
  errors,
}: {
  fieldId: string;
  errors: Record<string, string>;
}) {
  const error = errors[fieldId];
  if (!error) {
    return null;
  }

  return <div className="text-red-500 text-sm mt-1">{error}</div>;
}

type InventoryTypedInex = Inventory & {
  [key: string]: any;
};

type InventoryBicycleInex = InventoryBicycle & {
  [key: string]: any;
};

type InventorySkiInex = InventorySki & {
  [key: string]: any;
};

const forSaleSchema = z.object({
  name: z.string().min(1, "Tittel er påkrevd"),
  description: z.string().min(1, "Beskrivelse er påkrevd"),
  details: z.string().min(1, "Detaljer er påkrevd"),
  images: z.array(z.any()).min(1, "Bilder er påkrevd"),
  price: z.number().min(1, "Pris er påkrevd"),
});

const bicycleSchema = z.object({
  frameNumber: z.string().min(1, "Rammenummer er påkrevd"),
  bicycleSize: z.string().min(1, "Sykkelstørrelse er påkrevd"),
  bicycleType: z.string().min(1, "Sykkeltype er påkrevd"),
  // conditionGeneral: z.number().min(0, "Generell vurdering er påkrevd"),
  conditionCosmeticFrame: z.number().min(0, "Bruksmerker og ramme er påkrevd"),
  conditionSteeringStock: z.number().min(0, "Styrelager er påkrevd"),
  conditionCrank: z.number().min(0, "Kranklager er påkrevd"),
  conditionDrive: z.number().min(0, "Drev er påkrevd"),
  conditionCasette: z.number().min(0, "Kassett er påkrevd"),
  conditionChain: z.number().min(0, "Kjede er påkrevd"),
  conditionFrontTire: z.number().min(0, "Dekk foran er påkrevd"),
  conditionRearTire: z.number().min(0, "Dekk bak er påkrevd"),
  conditionFrontWheels: z.number().min(0, "Felg foran er påkrevd"),
  conditionRearWheels: z.number().min(0, "Felg bak er påkrevd"),
  conditionBrakes: z.number().min(0, "Bremser er påkrevd"),
  conditionDampersFront: z.number().nullable(),
  conditionDampersBack: z.number().nullable(),
  conditionBattery: z.number().nullable(),
  conditionMotor: z.number().nullable(),
  electric: z.boolean().nullable(),
  engineManufacturer: z.string().nullable(),
  motorNewtonmeter: z.number().nullable(),
  batteryManufacturer: z.string().nullable(),
  batteryDiagnoseSummary: z.string().nullable(),
  riderHeightFrom: z.number().nullable(),
  riderHeightTo: z.number().nullable(),
});

const skiSchema = z.object({
  brandId: z.string().min(1, "Brand er påkrevd"),
  skiType: z.nativeEnum(SkiType),
  length: z.number().min(1, "Lengde er påkrevd"),
  skins: z.boolean(),
  stiffness: z.nativeEnum(SkiStiffness),
  conditionGeneral: z.number().min(0, "Generell vurdering er påkrevd"),
});

const auctionSchema = z.object({
  auctionStart: z.string().min(1, "Auksjon start er påkrevd"),
  auctionEnd: z.string().min(1, "Auksjon slutt er påkrevd"),
  auctionStartPrice: z.number().min(1, "Auksjon utropspris er påkrevd"),
});

const combinedSchema = (
  objectType: ObjectType,
  status: InventoryStatusEnum
) => {
  // Start with the forSale schema as the base
  let schema = forSaleSchema;

  // Add bicycle or ski schema based on object type
  if (objectType === ObjectType.Bicycle) {
    schema = schema.merge(bicycleSchema);
  } else if (objectType === ObjectType.Ski) {
    schema = schema.merge(skiSchema);
  }

  // Add auction schema if status is auction
  if (status === InventoryStatusEnum.Auction) {
    schema = schema.merge(auctionSchema);
  }

  return schema;
};

export default function InventoryDetails({
  showHeader = false,
}: {
  showHeader?: boolean;
}) {
  const params = useParams();
  const STATUS = useEnum("InventoryStatus");

  const { loading, refetch, data } = usePartnerInventoryQuery({
    variables: { id: params.id },
  });

  if (loading) {
    return <Loading />;
  }

  const inventory = data?.inventory as InventoryTypedInex;

  if (!inventory || !params.id) {
    return <BikeNotFound id={params.id} />;
  }

  if (loading) {
    return <Loading />;
  }

  const cost = inventory?.bicycleIntakeForm?.bicyclePriceQuery?.offeredPrice;

  const sportiendaScore =
    inventory?.bicycleIntakeForm?.bicyclePriceQuery?.calculatedSportiendaScore;
  return (
    <InventoryDetailsWithData
      inventory={inventory}
      id={params.id}
      statusValues={STATUS}
      callback={refetch}
      cost={cost}
      sportiendaScore={sportiendaScore}
      showHeader={showHeader}
    />
  );
}

function BicycleInventoryDetails({
  createFieldProps,
  setData,
  data,
  errors,
}: {
  createFieldProps: (
    fieldId: string,
    fieldName: string,
    required?: boolean | undefined
  ) => any;
  errors: Record<string, string>;
  setData: any;
  data: StructuredDataType;
}) {
  const { sizes } = useGetSizes();

  const onUpdate =
    (name: string, parser = (x: any) => x) =>
    (e: any) => {
      setData({
        ...data,
        [name]: typeof e === "boolean" ? e : parser(e.target?.value),
      });
    };
  return (
    <>
      <Conditions
        data={data}
        setData={setData}
        conditions={conditionBicycle}
        errors={errors}
      />
      <Conditions
        data={data}
        setData={setData}
        conditions={conditionDampers}
        errors={errors}
      />

      <Conditions
        data={data}
        setData={setData}
        errors={errors}
        conditions={conditionMotorBattery}
      />
      <Field>
        <Label>Sykkelstørrelse</Label>
        <FieldError fieldId="bicycleSize" errors={errors} />
        <SelectFromOptions
          name="bicycleSize"
          value={data.bicycleSize === null ? "" : data.bicycleSize}
          onChange={onUpdate("bicycleSize")}
          options={sizes.map((size: any) => ({
            label: size.name,
            value: size.id,
          }))}
        />
      </Field>
      <Field>
        <Label>Sykkeltype</Label>
        <FieldError fieldId="bicycleType" errors={errors} />
        <SelectFromOptions
          name="bicycleType"
          value={data.bicycleType === null ? "" : data.bicycleType}
          options={bicycleTypes.map((type) => ({
            label: type.name,
            value: type.id,
          }))}
          onChange={onUpdate("bicycleType")}
        />
      </Field>
      <SwitchField className="bg-yellow-50 p-3 rounded-md border border-yellow-200 mt-2 mb-4">
        <Label form="electric" className="flex items-center font-semibold text-yellow-800">
          <BoltIcon className="h-5 w-5 mr-2 text-yellow-600" /> Elsykkel?
          <FieldError fieldId="electric" errors={errors} />
        </Label>
        <Switch
          color="amber"
          name="electric"
          value={data.electric}
          onChange={onUpdate("electric")}
        />
      </SwitchField>
      {data.electric && (
        <>
          <Field>
            <Label form="mileage">
              Odometer (km)
              <FieldError fieldId="milage" errors={errors} />
            </Label>
            <FieldError fieldId="milage" errors={errors} />
            <Description>Hvor mange kilometer har sykkelen gått? </Description>
            <Input
              name="mileage"
              required={false}
              type="number"
              value={data["mileage"]}
              onChange={onUpdate("mileage", (x) => (x ? parseInt(x) : null))}
            />
          </Field>

          <Field>
            <Label form="engineManufacturer">Motorprodusent</Label>
            <FieldError fieldId="engineManufacturer" errors={errors} />
            <Input
              name="engineManufacturer"
              required={false}
              type="text"
              value={data["engineManufacturer"]}
              onChange={onUpdate("engineManufacturer")}
            />
          </Field>

          <Field>
            <Label form="motorNewtonmeter">Motor newtonmeter</Label>
            <FieldError fieldId="motorNewtonmeter" errors={errors} />
            <Input
              name="motorNewtonmeter"
              required={false}
              type="number"
              value={data["motorNewtonmeter"]}
              onChange={onUpdate("motorNewtonmeter", (x) =>
                x ? parseInt(x) : null
              )}
            />
          </Field>

          <Field>
            <Label form="batteryManufacturer">
              Batteriprodusent og størrelse (kwh)
            </Label>
            <FieldError fieldId="batteryManufacturer" errors={errors} />
            <Input
              name="batteryManufacturer"
              required={false}
              type="text"
              value={data["batteryManufacturer"]}
              onChange={onUpdate("batteryManufacturer")}
            />
          </Field>

          <Field>
            <Label form="batteryDiagnoseSummary">Batteridiagnose</Label>
            <FieldError fieldId="batteryDiagnoseSummary" errors={errors} />
            <Textarea
              name="batteryDiagnoseSummary"
              required={false}
              value={data["batteryDiagnoseSummary"]}
              onChange={onUpdate("batteryDiagnoseSummary")}
            />
          </Field>
        </>
      )}
      <Field>
        <Label form="riderHeightFrom">Passer for rytter fra høyde (cm)</Label>
        <FieldError fieldId="riderHeightFrom" errors={errors} />
        <Input
          name="y"
          required={true}
          type="number"
          value={data["riderHeightFrom"]}
          onChange={onUpdate("riderHeightFrom", (x) =>
            x ? parseInt(x) : null
          )}
        />
      </Field>

      <Field>
        <Label form="riderHeightTo">Passer for rytter til høyde (cm)</Label>
        <FieldError fieldId="riderHeightTo" errors={errors} />
        <Input
          name="riderHeightTo"
          required={true}
          type="number"
          value={data["riderHeightTo"]}
          onChange={onUpdate("riderHeightTo", (x) => (x ? parseInt(x) : null))}
        />
      </Field>
      <Field>
        <Label form="frameNumber">Rammenummer</Label>
        <FieldError fieldId="frameNumber" errors={errors} />
        <Input
          name="frameNumber"
          required={true}
          type="text"
          value={data["frameNumber"]}
          onChange={onUpdate("frameNumber")}
        />
      </Field>
    </>
  );
}

function SkiInventoryDetails({
  createFieldProps,
  setData,
  data,
  errors,
}: {
  createFieldProps: (
    fieldId: string,
    fieldName: string,
    required?: boolean | undefined
  ) => any;
  setData: any;
  errors: Record<string, string>;
  data: StructuredDataType;
}) {
  const { data: brandsData, loading } = useSkiBrandsQuery();
  const skiBrands = brandsData?.brands?.nodes || [];
  if (loading) {
    return <LoadingInline />;
  }

  const onUpdate =
    (name: string, parser = (x: any) => x) =>
    (e: any) => {
      setData({
        ...data,
        [name]: typeof e === "boolean" ? e : parser(e.target?.value),
      });
    };
  return (
    <>
      <Field>
        <Label>
          Brand
          <FieldError fieldId="brandId" errors={errors} />
        </Label>
        <SelectFromOptions
          name="Brand"
          value={data?.brandId}
          options={skiBrands
            .filter((brand) => brand?.name !== null)
            .map((option) => ({
              label: option.name || "",
              value: option.id,
            }))}
          onChange={onUpdate("brandId")}
        />
      </Field>
      <Field>
        <Label form="model">
          Ski Type
          <FieldError fieldId="model" errors={errors} />
        </Label>
        <SelectFromEnum
          value={data?.skiType}
          enumObject={SkiType}
          onChange={onUpdate("skiType")}
        />
      </Field>
      <Field>
        <Label>
          Lengde
          <FieldError fieldId="length" errors={errors} />
        </Label>
        <Input
          name="length"
          type="number"
          value={data?.length}
          onChange={onUpdate("length", (x) => (x ? parseInt(x) : null))}
          required={true}
        />
      </Field>

      <SwitchField>
        <Label form="skiType">
          Skins?
          <FieldError fieldId="skins" errors={errors} />
        </Label>
        <Switch
          name="Skins?"
          value={data?.skins}
          onChange={onUpdate("skins")}
        />
      </SwitchField>
      <Field>
        <Label>
          Stivhet
          <FieldError fieldId="stiffness" errors={errors} />
        </Label>
        <SelectFromEnum
          name="Stivhet"
          value={data?.stiffness}
          enumObject={SkiStiffness}
          onChange={onUpdate("stiffness")}
        />
      </Field>
    </>
  );
}

type InventoryDetailsWithDataProps = {
  inventory: InventoryTypedInex;
  id: string;
  callback: any;
  statusValues: any;
  cost?: number | undefined | null;
  sportiendaScore?: number | undefined | null;
  showHeader?: boolean;
};

function FormSection({
  children,
  title,
  description,
}: {
  children: React.ReactNode;
  title: string;
  description?: string;
}) {
  return (
    <Fieldset className="mt-4">
      <Legend>{title}</Legend>
      {description && <Text>{description}</Text>}
      <FieldGroup>{children}</FieldGroup>
    </Fieldset>
  );
}

function InventoryDetailsWithData({
  inventory,
  id,
  callback,
  statusValues,
  cost,
  sportiendaScore,
  showHeader = true,
}: InventoryDetailsWithDataProps) {
  const params = useParams();
  const [edited, setEdited] = useState<Date | null>(null);
  const [data, setDataReal] = useState<StructuredDataType>({
    ...initialFormInventory,
  });
  const [skiData, setSkiDataReal] = useState<StructuredDataType>({
    ...initialFormInventorySki,
  });
  const [bicycleData, setBicycleDataReal] = useState<StructuredDataType>({
    ...initialFormInventoryBicycle,
  });

  const setDataWrapper =
    (setDataFunction: any) => (data: StructuredDataType) => {
      setDataFunction(data);
      setErrors({});
      setEdited(new Date());
    };
  const saveDataCallbackWrapper = () => {
    setEdited(null);
    callback();
  };
  // Get data from inventory
  const setData = setDataWrapper(setDataReal);
  const setSkiData = setDataWrapper(setSkiDataReal);
  const setBicycleData = setDataWrapper(setBicycleDataReal);

  const [imageLoading, setLoading] = useState(false);
  const [uploadFile] = useCreateInventoryImageMutation();
  const [deleteFile] = useRemoveInventoryImageMutation();
  const [updateImageOrder] = useUpdateInventoryImageOrderMutation();
  // Initialize and sort images by imageOrder
  const [images, setImages] = useState<InventoryImageType[]>(() => {
    // Create a new array to avoid sorting the original frozen array
    const unsortedImages = [...(inventory?.inventoryImages?.nodes || [])];
    return unsortedImages.sort((a, b) => {
      const orderA = a.imageOrder !== undefined && a.imageOrder !== null ? a.imageOrder : 0;
      const orderB = b.imageOrder !== undefined && b.imageOrder !== null ? b.imageOrder : 0;
      return orderA - orderB;
    });
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const validateForm = (newStatus: InventoryStatusEnum) => {
    const schema = combinedSchema(inventory.objectType, newStatus);
    const allData = { ...data, images, ...bicycleData, ...skiData };

    try {
      // Validate the data against the schema
      schema.parse(allData);

      // If validation passes, clear the errors
      setErrors({});
      return true; // Indicates validation success
    } catch (error) {
      if (error instanceof z.ZodError) {
        // Map Zod errors to a record of field-to-message
        const validationErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          if (err.path.length > 0) {
            validationErrors[err.path.join(".")] = err.message;
          }
        });

        // Update the errors state
        setErrors(validationErrors);
      }
      return false; // Indicates validation failure
    }
  };
  // Track form validation errors
  const calculateScore = () => {
    const allData = { ...data, ...bicycleData };
    const condtions =
      inventory.objectType === ObjectType.Bicycle &&
      inventory.type === InventoryType.Certified
        ? [...conditionGeneral, ...conditionBicycle]
        : conditionGeneral;
    const score = condtions.reduce((total, item) => {
      return total + (allData[item.key] ? allData[item.key] : 0);
    }, 0);
    setDataReal((data: StructuredDataType) => ({ ...data, score: score }));
  };

  useEffect(() => {
    Object.entries(initialFormInventory).forEach(([key]) => {
      const structuredData: StructuredDataType = {};
      if (key in inventory) {
        structuredData[key] = inventory[key];
      }
      setDataReal((data: StructuredDataType) => ({
        ...data,
        ...structuredData,
      }));
    });
    const inventorySki = inventory.inventorySkiById as InventorySkiInex;
    if (inventory.inventorySkiById && inventory.objectType === "SKI") {
      Object.entries(initialFormInventorySki).forEach(([key]) => {
        const structuredData: StructuredDataType = {};

        if (inventory.inventorySkiById && key in inventory.inventorySkiById) {
          structuredData[key] = inventorySki[key];
        }
        setSkiDataReal((skiData: StructuredDataType) => ({
          ...skiData,
          ...structuredData,
        }));
      });
    }

    if (
      inventory.inventoryBicycleById &&
      inventory.objectType === ObjectType.Bicycle
    ) {
      const inventoryBicycle =
        inventory.inventoryBicycleById as InventoryBicycleInex;
      Object.entries(initialFormInventoryBicycle).forEach(([key]) => {
        const structuredData: StructuredDataType = {};

        if (
          inventory.inventoryBicycleById &&
          key in inventory.inventoryBicycleById
        ) {
          structuredData[key] = inventoryBicycle[key];
        }
        setBicycleDataReal((bicycleData: StructuredDataType) => ({
          ...bicycleData,
          ...structuredData,
        }));
      });
    }
  }, [inventory]);

  useEffect(() => {
    if (inventory.type === "USERAD") {
      return;
    }
    calculateScore();
  }, [bicycleData, data.conditionGeneral, inventory.type]);


  // Sort images by imageOrder and create initialImages
  const initialImages = [...images]
    .sort((a, b) => {
      const orderA = a.imageOrder !== undefined && a.imageOrder !== null ? a.imageOrder : 0;
      const orderB = b.imageOrder !== undefined && b.imageOrder !== null ? b.imageOrder : 0;
      return orderA - orderB;
    })
    .map((i, index) => ({
      id: i.id,
      file: null,
      preview: `https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/${i.id}/public`,
      imageOrder: i.imageOrder !== undefined && i.imageOrder !== null ? i.imageOrder : index,
    }));
  console.log("Images from server:", images);
  console.log("Sorted initial images:", initialImages);

  const onImageChange = async (images: [any]) => {
    try {
      setLoading(true);

      for (const img of images) {
        try {
          const result = await uploadFile({
            variables: {
              inventoryUuid: params.id,
              contentType: img.file.type,
            },
          });

          const uploadUrl = result?.data?.createInventoryImage?.uploadURL;

          if (uploadUrl) {
            let formData = new FormData();
            formData.append("file", img.file);
            await fetch(uploadUrl, {
              method: "POST",
              body: formData,
            });
          } else {
            alert("Failed to upload. No upload URL found.");
          }

          setImages((images) => [
            ...images,

            {
              __typename: "InventoryImage",
              id: result?.data?.createInventoryImage?.id,
            } as InventoryImageType,
          ]);
        } catch (e) {
          // TODO: Error message
          console.error(e);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const onImageRemove = async (image: any) => {
    if (image.id) {
      if (window.confirm("Are you sure you want to delete this image?")) {
        setImages(images.filter((i) => i.id !== image.id));
        await deleteFile({
          variables: {
            id: image.id,
          },
        });
      }
    }
  };
  
  const onImageReorder = async (imageId: string, newOrder: number) => {
    try {
      console.log(`Reordering image ${imageId} to position ${newOrder}`);
      
      // Create a deep copy of images array with new objects to avoid modifying read-only properties
      const clonedImages = images.map(img => ({...img}));
      
      // Find the current index of the image in our array
      const imageIndex = clonedImages.findIndex(img => img.id === imageId);
      if (imageIndex === -1) {
        console.log("Image not found in current state");
        return;
      }
      
      // Remove the image from its current position
      const [imageToMove] = clonedImages.splice(imageIndex, 1);
      
      // Insert it at the new position
      clonedImages.splice(newOrder, 0, imageToMove);
      
      // Create new objects with updated imageOrder to avoid modifying read-only properties
      const updatedImages = clonedImages.map((img, idx) => ({
        ...img,
        imageOrder: idx
      }));
      
      // Update the local state immediately for a responsive UI
      setImages(updatedImages);
      
      console.log("Updated local state with new order");
      
      // Then make the API call to persist the change
      const result = await updateImageOrder({
        variables: {
          id: imageId,
          imageOrder: newOrder,
        },
      });
      
      console.log("API call complete:", result);
      
      // If the API call fails, we could revert the state here
      if (!result?.data?.updateInventoryImage?.inventoryImage) {
        console.error("Failed to update image order in database");
      }
    } catch (error) {
      console.error("Error reordering image:", error);
    }
  };

  const onUpdate =
    (name: string, parser = (x: any) => x) =>
    (event: any) => {
      const value = event.target.value || event.target.checked || event;

      setData({
        ...data,
        [name]: parser(value),
      });
    };

  const createFieldProps = (
    fieldId: string,
    fieldName: string,
    required?: boolean | undefined
  ) => ({
    id: fieldId,
    name: fieldId,
    value: data[fieldId] || "",
    onChange: onUpdate(fieldId),
    required:
      required === null || typeof required === "undefined" || required
        ? true
        : false,
  });

  const cleanData = () => {
    const checkArr = [...conditionDampers, ...conditionMotorBattery].map(
      (obj) => obj.key
    );

    const result = Object.entries(data)
      .map(([key, value], index) => {
        return [key, checkArr.includes(key) && value === "" ? null : value];
      })
      .reduce((x, arr) => ({ ...x, [arr[0] as any]: arr[1] }), {});

    return result;
  };
  const publicUrlEnabled = ["FOR_SALE", "FOR_SALE_HIDDEN", "AUCTION"].includes(
    data.status
  );
  // Check if preview URL is enabled
  const openPreview = () => {
    if (!publicUrlEnabled) {
      return;
    }
    window.open(`${publicWebsite}/s/${inventory?.id}`, "_blank");
  };
  return (
    <>
      <StickyActionBar
        header={
          <InventoryHeader
            index={0}
            inventory={inventory}
            statusWorkflow={
              <InventoryStatus
                inventoryId={id}
                edited={edited}
                callback={saveDataCallbackWrapper}
                initialStatus={inventory.status || "draft"}
                validateTransition={validateForm}
              />
            }
          />
        }
        errors={
          errors && Object.entries(errors).length ? (
            <div className="grow text-sm font-semibold text-sportred">
              Det er {Object.entries(errors).length} feil, vennligst rett alle
              før du går videre.
            </div>
          ) : undefined
        }
      >
        <Button
          color="white"
          className="group relative"
          onClick={openPreview}
          disabled={!publicUrlEnabled}
        >
          <EyeIcon />
          Se annonse
          {!publicUrlEnabled && (
            <div className="absolute hidden group-hover:flex -left-5 -top-2 -translate-y-full w-48 px-2 py-1 bg-gray-700 rounded-lg text-center text-white text-sm after:content-[''] after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-gray-700">
              Må legges ut for salg før du kan se annonsen, bruk gjerne skjult
              link.
            </div>
          )}
        </Button>
        <SaveButtons
          id={inventory.id}
          data={data}
          edited={edited}
          skiData={inventory.objectType === "SKI" ? skiData : null}
          bicycleData={inventory.objectType === "BICYCLE" ? bicycleData : null}
          callback={saveDataCallbackWrapper}
        />
      </StickyActionBar>
      <Fieldset>
        <Legend>Rediger annonse</Legend>
        <FieldGroup>
          <Field>
            <Label>Tittel</Label>
            <Description>
              Tittel som vises på sykkelen i oversikten. Denne skal normal ikke
              endres og skal følge formatet "År Merke Modell"
            </Description>
            <Input
              name="name"
              value={data.name || undefined}
              onChange={onUpdate("name")}
              required
              type="text"
            />
          </Field>
          <Field>
            <Label>Beskrivelse</Label>
            <Textarea
              {...createFieldProps("description", "Description", true)}
              rows={25}
            />
          </Field>
          <Field>
            <Label>Spesifikasjoner</Label>
            <Description>
              Her kan du legge til en teknisk beskrivelse av sykkelen. Dette vil
              være synlig for kunder som ser på sykkelen. Hvis vi har tekniske
              spesifikasjoner for sykkelen vil vi lage en beskrivelse
              automatisk.
            </Description>

            <Textarea
              {...createFieldProps("details", "Spesifikasjoner", true)}
              rows={25}
            />
          </Field>
          <Field>
            <Label>
              Pris
              <FieldError fieldId="price" errors={errors} />
            </Label>
            <Description>
              Utsalgspris inkludert MVA. For auksjon se nederst på siden.
            </Description>
            <Input
              name="price"
              value={data.price || undefined}
              onChange={onUpdate("price", (x) => (x ? parseInt(x) : null))}
              required
              type="number"
            />
          </Field>
          <Field>
            <Label form="priceNew">Ny pris</Label>
            <Description>
              {inventory.originalPrice
                ? `Beregnet pris basert på data fra 99 spokes: ${inventory.originalPrice}`
                : "Har ikke beregnet pris."}{" "}
              Du kan overstyre prisen her.
            </Description>
            <FieldError fieldId="priceNew" errors={errors} />
            <Input
              name="priceNew"
              required={false}
              type="text"
              value={data["priceNew"]}
              onChange={onUpdate("priceNew", (x) => (x ? parseInt(x) : null))}
            />
          </Field>{" "}
        </FieldGroup>
      </Fieldset>

      <FormSection title="Bilder">
        {images.length > 0 && (
          <div className="text-sm text-gray-600 mb-3 bg-gray-50 p-2 rounded border border-gray-200">
            <p className="font-medium">Tips:</p>
            <p>Du kan endre rekkefølgen på bilder ved å bruke pil-knappene. Hovedbildet er det første bildet.</p>
          </div>
        )}
        <ImageUpload
          label={null}
          imageList={initialImages}
          onChange={onImageChange}
          onRemoved={onImageRemove}
          onReorder={onImageReorder}
        />
      </FormSection>

      {inventory.objectType === "BICYCLE" && (
        <FormSection title="Sykkel">
          <BicycleInventoryDetails
            errors={errors}
            createFieldProps={createFieldProps}
            setData={setBicycleData}
            data={bicycleData}
          />
        </FormSection>
      )}
      {inventory.objectType === "SKI" && (
        <FormSection title="Ski">
          <SkiInventoryDetails
            errors={errors}
            createFieldProps={createFieldProps}
            setData={setSkiData}
            data={skiData}
          />
        </FormSection>
      )}

      {data.status === "FOR_SALE" && (
        <FormSection title="Finn.no">
          <ZebraDetailList
            inline
            rows={[
              [
                "Finn id",
                inventory.finnCode && (
                  <a
                    className={"text-oxford-blue underline"}
                    target="_blank"
                    href={`https://www.finn.no/bap/webstore/ad.html?finnkode=${inventory.finnCode}`}
                  >
                    {inventory.finnCode}
                  </a>
                ),
              ],
              ["Sist syncet", inventory.finnLastSync],
              ["Bilder syncet", inventory.finnImagesUploaded],
            ]}
          />
          <SyncToFinnButton inventoryId={inventory?.id} />
        </FormSection>
      )}
      <FormSection title="Auksjon">
        <Field>
          <Label>Auksjon start</Label>

          <Input
            {...createFieldProps("auctionStart", "Auksjon start")}
            type="datetime-local"
            required={false}
          />
        </Field>
        <Field>
          <Label>Auksjon slutt</Label>
          <Input
            {...createFieldProps("auctionEnd", "Auksjon slutt")}
            type="datetime-local"
            required={false}
          />
        </Field>
        <Field>
          <Label>Auksjon utropspris</Label>
          <Input
            id="auctionStartPrice"
            name="auctionStartPrice"
            value={data?.auctionStartPrice || null}
            onChange={onUpdate("auctionStartPrice", (x) =>
              x ? parseInt(x) : null
            )}
            type="number"
            required={false}
          />
        </Field>
      </FormSection>
    </>
  );
}
