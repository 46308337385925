import {
  Category,
  ListDescription,
  Locale,
  Navigation,
  Option,
  SchemaEntry,
  Service,
  SubCategory,
} from "@app/types";

// @ts-ignore
const packageJson = require("../../../package.json");

// TODO: customise this with your own settings!

export const fromEmail =
  '"PostGraphile Starter" <no-reply@examples.graphile.org>';
export const awsRegion = "us-east-1";
export const projectName = packageJson.projectName.replace(/[-_]/g, " ");
export const companyName = projectName; // For copyright ownership
export const emailLegalText =
  // Envvar here so we can override on the demo website
  process.env.LEGAL_TEXT || "<Insert legal email footer text here >";

export const domain = process.env.AUTH0_BASE_URL || "https://sportienda.com";

export function conditionsFromData(data: any): SchemaEntry[] {
  return [
    standardConditionSelectors(data),
    damperConditionSelectors(data),
    data.electric ? electriConditionSelectors : [],
    data.bicycleType === "RACE" ? raceBreakType : [],
  ].flat() as SchemaEntry[];
}

export function dbConditionsFromData(data: any): SchemaEntry[] {
  const graphqlConditions = conditionsFromData(data);
  // key from cammelCase to snake_case
  return graphqlConditions.map((condition) => {
    const [key, ...rest] = condition;
    return [key.replace(/([A-Z])/g, "_$1").toLowerCase(), ...rest];
  });
}
export const showDampers = ["HARDTAIL", "FULL_SUSPENSION"];

export const typesFromData = (data: any) => typeSelectors;

export const TextArea = "textarea";

export const emailLists = {
  5: "Nyhetsbrev",
};

export const priceQuerySteps = [
  { id: "01", name: "Sykkeltype", rows: typesFromData },
  { id: "02", name: "Tilstand", rows: conditionsFromData },
  { id: "03", name: "Tilbud" },
  { id: "04", name: "Detaljer og Personalia" },
  { id: "05", name: "Oppsummering" },
];

export const intakeSteps = [
  { id: "01", name: "Tilstand" },
  { id: "02", name: "Oppsummering og innsending" },
];

export const bicycleTypes = [
  {
    id: "HARDTAIL",
    name: "Hardtail",
  },
  {
    id: "FULL_SUSPENSION",
    name: "Fulldemper",
  },
  {
    id: "RACE",
    name: "Landevei",
  },
  {
    id: "GRAVEL",
    name: "Grusracer",
  },
  {
    id: "CITY",
    name: "Bysykkel",
  },
  {
    id: "CARGO",
    name: "Lastesykkel",
  },
  {
    id: "YOUTH",
    name: "Barnesykkel",
  },
  {
    id: "OTHER",
    name: "Tempo / Triatlon",
  },
];

export const bicycleDriveTypes = [
  {
    id: "CHAIN",
    name: "Kjede",
  },
  {
    id: "BELT",
    name: "Belte",
  },
];

export const typeSelectors: SchemaEntry[] = [
  ["bicycleType", "Bekreft sykkeltype", bicycleTypes],
  ["electric", "Bekreft el-sykkel?", Boolean, true],
  ["driveType", "Bekreft drivverk", bicycleDriveTypes],
];

const extraIntakeFields = [
  ["frameNumber", "Rammenummer", String],
  ["batteryIncluded", "Batteri Inkludert", Boolean, true],
  ["keysIncluded", "Nøkler Inkludert", Boolean, true],
  ["keyBrand", "Nøkkelmerke", String, false],
  ["keyNumber", "Nøkkelnumber", String, false],
  ["chargerIncluded", "Lader Inkludert", Boolean, true],
  ["batteryStatusV", "Batteristatus [V]", Number, true],
  ["batteryStatusAh", "Batteristatus [Ah]", Number, true],
  ["batteryStatusWh", "Batteristatus [Wh]", Number, true],
  ["comment", "Kommentar", TextArea, true],
  ["estimatedServiceCost", "Beregnet servicekostnad", Number, true],
];

export const extraIntakeFieldsFromData = (data: any) => extraIntakeFields;

const standardConditionSelectors = (data: any) => {
  return [
    [
      "conditionCosmeticFrame",
      "Bruksmerker og ramme",
      [
        {
          name: "ødelagt",
          description: "store skader i byggematerialet",
          id: -126,
        },
        {
          name: "god stand",
          description: "moderate riper og skrammer",
          id: 10,
        },
        {
          name: "meget god stand",
          description: "Utmerket stand, knapt synlige riper",
          id: 15,
        },
        { name: "som ny", description: "Ikke vært i bruk", id: 20 },
      ],
    ],
    [
      "conditionBottomBracket",
      data?.driveType === "BELT" ? "Nav" : "Drev",
      [
        { name: "Må byttes", description: "ødelagt/utslitt", id: 0 },
        { name: "god stand", description: "fungerer ut sesongen", id: 5 },
        {
          name: "meget god stand",
          description: "minst en sesong igjen",
          id: 12,
        },
        { name: "som nytt", description: "aldri vært i bruk", id: 14 },
      ],
    ],
    [
      "conditionCasette",
      data?.driveType === "BELT" ? "Tannhjul" : "Kassett",
      [
        { name: "må byttes", description: "ødelagt/utslitt", id: 0 },
        {
          name: "god stand/bør ha service",
          description: "fungerer ut sesongen",
          id: 5,
        },
        {
          name: "meget god stand",
          description: "minst en sesong igjen",
          id: 12,
        },
        { name: "som nytt", description: "aldri vært i bruk", id: 14 },
      ],
    ],
    [
      "conditionChain",
      data?.driveType === "BELT" ? "Belte" : "Kjede",
      [
        { name: "må byttes", description: "ødelagt/utslitt", id: 0 },
        { name: "god stand", description: "fungerer ut sesongen", id: 4 },
        { name: "meget god stand", description: "ubetydelig slitasje", id: 8 },
        { name: "som nytt", description: "aldri vært i bruk", id: 10 },
      ],
    ],
    [
      "conditionTiresFront",
      "Dekk foran",
      [
        { name: "må byttes", description: "ødelagt/utslitt", id: 0 },
        {
          name: "god stand/bør ha service",
          description: "noe slitasje, men fungerer ut sesongen",
          id: 6,
        },
        { name: "meget god stand", description: "ubetydelig slitasje", id: 10 },
        { name: "som nytt", description: "aldri vært i bruk", id: 12 },
      ],
    ],
    [
      "conditionTiresBack",
      "Dekk bak",
      [
        { name: "må byttes", description: "ødelagt/utslitt", id: 0 },
        {
          name: "god stand",
          description: "noe slitasje, men fungerer ut sesongen",
          id: 6,
        },
        { name: "meget god stand", description: "ubetydelig slitasje", id: 10 },
        { name: "som nytt", description: "aldri vært i bruk", id: 12 },
      ],
    ],
    [
      "conditionWheelsFront",
      "Felg/hjulsett Foran",
      [
        { name: "må byttes", description: "ødelagt/utslitt", id: -50 },
        {
          name: "god stand/bør ha service",
          description: "noe slitasje, men fungerer ut sesongen",
          id: 6,
        },
        { name: "meget god stand", description: "ubetydelig slitasje", id: 13 },
        { name: "som nytt", description: "aldri vært i bruk", id: 18 },
      ],
    ],
    [
      "conditionWheelsBack",
      "Felg/hjulsett Bak",
      [
        { name: "må byttes", description: "ødelagt/utslitt", id: -50 },
        {
          name: "bør ha service",
          description: "kast i hjul/nav må smøres",
          id: 6,
        },
        {
          name: "meget god stand",
          description: "brukt, men fungerer optimalt",
          id: 13,
        },
        { name: "som nytt", description: "aldri vært i bruk", id: 18 },
      ],
    ],
    [
      "conditionBrakes",
      "Bremser",
      [
        {
          name: "må byttes",
          description: "ødelagt/utslitte klosser eller skiver",
          id: 0,
        },
        {
          name: "bør ha service",
          description: "Bremsene fungerer, men bør luftes/justeres",
          id: 5,
        },
        {
          name: "meget god stand",
          description: "God bremsekraft/ingen ulyder",
          id: 7,
        },
        { name: "som nytt", description: "aldri vært i bruk", id: 8 },
      ],
    ],
  ];
};

const damperConditionSelectors = (data: any) => {
  return [
    [
      "conditionDampersFront",
      "Fordemper (Må velges om sykkelen har fordemper)",
      [
        {
          id: -5000,
          name: "Ødelagt",
          description: "fungerer ikke som forventet",
        },
        {
          id: -2500,
          name: "Trenger service",
          description: "Over 12 mnd siden demperservice",
        },
        {
          id: -500,
          name: "Meget god stand",
          description:
            "har hatt demperservice siste 12 mnd - dokumentasjon kreves",
        },
        { id: 0, name: "Som ny", description: "aldri vært i bruk" },
        ...(data.bicycleType !== "FULL_SUSPENSION"
          ? [
              {
                id: null,
                name: "Sykkelen har ikke dempere",
              },
            ]
          : []),
      ],
      data.bicycleType !== "FULL_SUSPENSION",
    ],
    ...(data.bicycleType === "FULL_SUSPENSION"
      ? [
          [
            "conditionDampersBack",
            "Bakdemper.",
            [
              {
                id: -5000,
                name: "Ødelagt",
                description: "fungerer ikke som forventet",
              },
              {
                id: -1500,
                name: "God stand/Trenger service",
                description: "Over 12 mnd siden demperservice",
              },
              {
                id: -500,
                name: "Meget god stand",
                description:
                  "har hatt demperservice siste 12 mnd - dokumentasjon kreves",
              },
              { id: 0, name: "Som ny", description: "aldri vært i bruk" },
            ],
          ],
        ]
      : []),
  ];
};

const electriConditionSelectors = [
  [
    "brandMotor",
    "Merke, elektrisk motor",
    [
      { id: "Yamaha", name: "Yamaha" },
      { id: "Shimano", name: "Shimano" },
      { id: "Bosch", name: "Bosch" },
      { id: "other", name: "Andre" },
    ],
  ],
  ["km", "El-sykkelens kjørelengde i km (se sykkelcomputer)", Number],

  //, "Shimano","Bosch",	"Andre"
];

const raceBreakType = [
  [
    "breakType",
    "Bremsetype",
    [
      { id: "DISC", name: "Bremseskiver" },
      { id: "RIM", name: "Felgbrems" },
    ],
  ],
];

export const ListDescriptions: ListDescription[] = [
  { id: 5, name: "Nyhetsbrev", description: "" },
];

export const InventoryConditions: Option[] = [
  { name: "God stand", id: 2 },
  { name: "Meget god stand", id: 3 },
  { name: "Nytt", id: 4 },
];

export const locales: Locale[] = [
  {
    name: "Norge",
    flag: "no",
    locale: "no",
  },
  // {
  //   name: "Sverige",
  //   flag: "se",
  //   locale: "se",
  // },
];

const skiSubcategory: SubCategory = {
  id: "ski",
  name: "Ski",
  type: "SKI",
  filter: {
    objectType: {
      in: ["SKI"],
    },
  },
};

const bikeSubcategory: SubCategory = {
  id: "bike",
  name: "Bike",
  type: "BICYCLE",
  filter: {
    objectType: {
      in: ["BICYCLE"],
    },
  },
};

export const structure: Category[] = [
  {
    id: "bike",
    name: "Sykkel",
    showSubCategories: true,
    description: `Alle våre sykler er sertifiserte, som vil si at de er nøye inspisert, vasket, og fått service før salg.

Vi gjennomgår 126 kontrollpunkter for kvalitet og sender sykkelen til deg – klar til bruk.

Usikker på valg av sykkel? [Prøv vår sykkelguide!](/no/x)`,
    filter: {
      objectType: {
        in: ["BICYCLE"],
      },
    },
    type: "bicycle",
    subCategories: [
      {
        id: "electric",
        name: "Elsykkel",
        description:
          "Med en brukt elsykkel fra Sportienda får du topp kvalitet til en lavere pris, uten å gå på kompromiss med ytelse eller sikkerhet. Alle våre elektriske sykler er sertifiserte, som vil si at de er grundig inspisert, testet for batterievne, fått service, og klargjort for mange flere mil!\n\nSer du etter en sykkel med plass til barn og større gods? Sjekk ut våre [lastesykler](/no/bike/cargo).",
        type: "electric",
        filter: {
          electric: { equalTo: true },
        },
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/8ebbcf5e-5e46-4b7b-e078-3ededd182300/listing",
      },
      {
        id: "road",
        name: "Landeveissykkel",
        description: `Ser du etter en racersykkel? Her kan du finne landeveisykkel til dame og herre fra kjente merker som BMC, Trek, Focus, MMR, Pinarello, Giant, Willier, Specialized og flere.

[Ta kontakt](/no/kontaktskjema) om du er usikker på hvilken du bør velge!`,
        filter: {
          bicycleType: {
            in: ["RACE"],
          },
        },
        type: "race",
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/7ab5db92-486d-44e4-daee-41967fbaa000/listing",
      },
      {
        id: "gravel",
        name: "Gravelsykkel",
        description:
          "En racersykkel med kraftigere ramme og grovere dekk slik at du også kan sykle på grus- og skogsstier. Gravelsykler blir ofte kalt grusracere eller gravel bikes. Hos Sportienda kan du kjøpe grusracere fra Giant, MMR, Specialized, Cannondale m.fl.",
        type: "gravel",
        filter: {
          bicycleType: {
            in: ["GRAVEL"],
          },
        },
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/56f053c4-5615-4c8b-228e-c256066de600/listing",
      },
      {
        id: "city",
        name: "Bysykkel",
        description:
          "I denne kategorien finner du både elsykler og de uten motor, men de har alle til felles at de er designet for kortere turer i byen, og kombinerer ofte komfort med praktisk transport. Bysyklene har ofte reflekser, bagasjebrett og skjermer, og noen har også lykter og kurv. I vårt utvalg finner du både klassiske og moderne design, slik at du kan velge en sykkel som passer din stil og behov.",
        filter: {
          bicycleType: {
            in: ["CITY"],
          },
        },
        type: "city",
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/21a0c44c-18a9-4a01-aea9-e24f94dbe100/listing",
      },
      {
        id: "hardtail",
        name: "Hardtail",
        description:
          'Navnet tilsier at sykkelen har en "hard hale", og brukes om terrengsykler med kun fordemper. Slike sykler kan ofte brukes på alle typer underlag, og kan fint brukes til pendling og dagligdags bruk såvel som i skog og mark.',
        filter: {
          bicycleType: {
            in: ["HARDTAIL"],
          },
        },
        type: "hardtail",
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/528820f4-7b06-4390-61f3-94d3924bb400/listing",
      },
      {
        id: "fullsuspension",
        name: "Fulldemper",
        description:
          'Terrengsykler med både for- og bakdempere for økt respons og kontroll under krevende forhold. Her kan du finne du fulldempet terrengsykkel med dropper post, elektriske fulldempere og mye annet fra bl.a. Kona, Scott, Felt, Giant og Orbea. Ser du etter en "vanlig" terrengsykkel med kun fordemper kan du se gjennom [våre hardtails](/no/bike/hardtail).',
        filter: {
          bicycleType: {
            in: ["FULL_SUSPENSION"],
          },
        },
        type: "full_suspension",
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/239a7836-c258-480a-bf39-e3c566b6e300/listing",
      },
      {
        id: "youth",
        name: "Barnesykkel",
        description:
          "Barn vokser fort, men det betyr ikke at en juniorsykkel skal være en kortvarig investering. En solid barnesykkel av høy kvalitet gir trygghet, holdbarhet og verdi over tid. Med en kvalitetskontrollert brukt sykkel velger du en sikker, rimelig og bærekraftig løsning som varer – både for nåværende og fremtidige små syklister.",
        filter: {
          bicycleType: {
            in: ["YOUTH"],
          },
        },
        type: "youth",
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/21a0c44c-18a9-4a01-aea9-e24f94dbe100/listing",
      },
      {
        id: "cargo",
        name: "Lastesykkel",
        description:
          "For deg som har barn eller behov for å transportere mer. Også kjent som cargo bike eller cargo sykkel. Dagens elektriske lastesykkel er utviklet for å være både praktisk og komfortabel, og tilbyr en miljøvennlig og enkel transportløsning i hverdagen. Her kan du kjøpe merker som Benno, Hercules, KTM, Cannondale, Riese & Müller.",
        filter: {
          bicycleType: {
            in: ["CARGO"],
          },
        },
        type: "cargo",
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/21a0c44c-18a9-4a01-aea9-e24f94dbe100/listing",
      },
      {
        id: "other",
        name: "Temposykkel",
        description:
          "Temposykler - ofte kalt triatlonsykler eller TT-sykkel - er designet for å redusere luftmotstand og oppnå høye hastigheter. Syklene bygger på tre hovedpunkter: Aerodynamikk, sittestilling, og spesialtilpassede komponenter. Om målet er å gjennomføre et triatlon og tiden ikke er så viktig, kan du også bruke en [gravelsykkel](/no/bike/gravel) eller [landeveissykkel](/no/bike/road).",
        filter: {
          bicycleType: {
            in: ["OTHER"],
          },
        },
        type: "other",
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/21a0c44c-18a9-4a01-aea9-e24f94dbe100/listing",
      },
    ],
  },
  {
    id: "ski",
    name: "Ski",
    description: `Vi har et stort utvalg ski av høy kvalitet. Vi jobber kun med anerkjente merkevarer som leverer høy kvalitet. Bruk vår kjøpeguide om du trenger hjelp med å finne skiene som passer dine behov.

[Kjøpeguide](/no/x)
`,
    type: "ski",
    showSubCategories: true,
    hidden: true,
    filter: {
      objectType: {
        in: ["SKI"],
      },
    },
    subCategories: [
      {
        id: "classic",
        name: "Langrenn Klassisk",
        description: `Vi har et stort utvalg ski av høy kvalitet. Vi jobber kun med anerkjente merkevarer som leverer høy kvalitet. Bruk vår kjøpeguide om du trenger hjelp med å finne skiene som passer dine behov.

[Kjøpeguide](/no/x)`,
        filter: {
          skiType: {
            in: ["NORDIC_CLASIC"],
          },
        },
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/9c88abf5-29e9-4ddd-f417-02fec1367000/listing",
        type: "nordic_clasic",
      },
      {
        id: "skating",
        name: "Langrenn Skating",
        description:
          "Vi har et stort utvalg ski av høy kvalitet. Vi jobber kun med anerkjente merkevarer som leverer høy kvalitet. Bruk vår kjøpeguide om du trenger hjelp med å finne skiene som passer dine behov.",
        filter: {
          skiType: {
            in: ["NORDIC_SKATING"],
          },
        },
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/3c9b9c6a-1358-4701-5b7a-3499d7da1b00/listing",
        type: "nordic_skating",
      },
      {
        id: "alpine",
        name: "Alpin",
        description: `Vi har et stort utvalg ski av høy kvalitet. Vi jobber kun med anerkjente merkevarer som leverer høy kvalitet. Ta kontakt om du har spørsmål eller ønsker montering av bindinger.

[Kjøpeguide](/no/x)
          `,
        filter: {
          skiType: {
            in: ["ALPINE"],
          },
        },
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/3ef0a087-5e36-4091-92ff-12210e074500/listing",
        type: "alpine",
      },
      {
        id: "randonee",
        name: "Randonee",
        description: `Vi har et stort utvalg ski av høy kvalitet. Vi jobber kun med anerkjente merkevarer som leverer høy kvalitet. Ta kontakt om du har spørsmål eller ønsker montering av bindinger.

[Kjøpeguide](/no/x)
          `,
        filter: {
          skiType: {
            in: ["RANDONEE"],
          },
        },
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/d94fb3d8-b3ba-4018-2eaa-6f30a448a900/listing",
        type: "randonee",
      },
      {
        id: "accessories",
        name: "Tilbehør",
        filter: {
          skiType: {
            in: ["ACCESSORY"],
          },
        },
        hidden: true,
        type: "accessory",
      },
      // {
      //   id: "telemark",
      //   name: "Telemark",
      //   filter: {
      //     skiType: {
      //       in: ["TELEMARK"],
      //     },
      //   },
      //   img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/0436a328-3448-4b3a-88fd-0ac2b6e16000/listing",
      //   type: "TELEMARK",
      // },
      // {
      //   id: "touring",
      //   name: "Fjellski",
      //   filter: {
      //     skiType: {
      //       in: ["TOURING"],
      //     },
      //   },
      //   img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/bc3ca143-1c9f-42d3-d31f-88003a83b600/listing",
      //   type: "TOURING",
      // },
      {
        id: "snowboard",
        name: "Snowboard",
        filter: {
          skiType: {
            in: ["SNOWBOARD"],
          },
        },
        img: "https://imagedelivery.net/ILVXxThkldxr02lxmrM6cQ/fd5aa1b0-9a82-4111-9377-16cdcca52a00/listing",
        type: "snowboard",
      },
    ],
  },
];

const mainNavItems = [
  {
    id: "sell",
    name: "Selg din sykkel",
  },

  {
    id: "service",
    name: "Verksted og tjenester",
  },
  {
    id: "why",
    name: "Hvorfor Sportienda?",
  },
];

export function getNavigation(locale: string = "no"): Navigation {
  const categories = structure
    .filter((category) => category.showSubCategories && !category.hidden)
    .map((category) => ({
      name: category.name,
      id: category.id,
      featured: category.subCategories
        ?.filter((subCategory) => !subCategory?.hidden)
        .map((subCategory) => ({
          id: subCategory.id,
          name: subCategory.name,
          href: `/${locale}/${category.id}/${subCategory.id}`,
          imageSrc: subCategory.img,
        })),
    }));
  const pages = structure
    .filter((category) => !category.showSubCategories)
    .map((category) => ({
      name: category.name,
      id: category.id,
      href: `/${locale}/${category.id}`,
      className: category.className || "",
    }));

  const mainNav = mainNavItems.map((item: any) => ({
    name: item.name,
    href: `/${locale}/${item.id}`,
  }));

  return { categories, pages, mainNav };
}

export function getCategory(id: string) {
  return structure.find((category) => category.id === id);
}

export function getSubCategory(categoryId: string, subCategoryId: string) {
  return getCategory(categoryId)?.subCategories?.find(
    (subCategory) => subCategory.id === subCategoryId
  );
}

function uuid4toYouTubeStyleId(uuid4: string) {
  const hex = uuid4.replace(/-/g, "");
  let num = BigInt("0x" + hex);
  const base64chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";
  let youtubeStyleId = "";

  while (num > 0) {
    youtubeStyleId = base64chars[Number(num % BigInt(64))] + youtubeStyleId;
    num /= BigInt(64);
  }

  return youtubeStyleId;
}

export function youtubeStyleIdToUuid4(youtubeStyleId: string) {
  const base64chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_";
  let num = BigInt(0);

  for (let i = 0; i < youtubeStyleId.length; i++) {
    num = num * BigInt(64) + BigInt(base64chars.indexOf(youtubeStyleId[i]));
  }

  let hex = num.toString(16).padStart(32, "0");

  // Insert hyphens at the appropriate positions to format as a UUID4
  hex =
    hex.slice(0, 8) +
    "-" +
    hex.slice(8, 12) +
    "-" +
    hex.slice(12, 16) +
    "-" +
    hex.slice(16, 20) +
    "-" +
    hex.slice(20);

  return hex;
}

function nameToUrl(name: string) {
  return name
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, "-")
    .replace(/(^-|-$)/g, "");
}

type InventoryUrlObject = {
  id: string;
  object_type: string;
  name: string | null;
  inventory_bicycle?: { bicycle_type: any };
  inventory_ski?: { ski_type: any };
};

export const createInventoryUrl = (
  locale: string,
  object: InventoryUrlObject | undefined
) => {
  if (!object || !object.name) return "";
  //look up the category from type in structure and use id in url
  const id = object.id;

  const objectType = object.object_type;
  const subType =
    object?.inventory_bicycle?.bicycle_type || object?.inventory_ski?.ski_type;
  if (!objectType || !subType) return null;
  const category = structure.find((item) => item.type === objectType)?.id;
  const subCategory = structure
    .find((item) => item.type === objectType)
    ?.subCategories?.find((item) => item.type === subType)?.id;
  if (!category || !subCategory) return null;
  return `/${locale}/${category}/${subCategory}/${nameToUrl(
    object.name
  )}/${uuid4toYouTubeStyleId(id)}`;
};

export const services: Service[] = [
  // {
  //   id: "langrenn",
  //   name: "Oppmåling av langrennski",
  //   bookingId: "langrenn",
  // },
  // {
  //   id: "vinterlagring",
  //   name: "Vinterlagring av sykkel",
  //   bookingId: "vinterlagring",
  // },

  {
    id: "service",
    name: "Sykkelslverksted og service",
    bookingId: "service",
  },
];

type trelloConfigType = {
  trelloBoardId: string;
  trelloPriceQueryListId: string;
  trelloOrderListId: string;
};

type trelloConfigLookupType = {
  [key: string]: trelloConfigType;
};

export const node_env = process.env.NODE_ENV;

const trelloConfigLookup: trelloConfigLookupType = {
  development: {
    trelloBoardId: "2bUK139U",
    trelloPriceQueryListId: "656a23815f307c61629ff6af",
    trelloOrderListId: "656a23815f307c61629ff6b0",
  },
  production: {
    trelloBoardId: "NxvD64ZU",
    trelloPriceQueryListId: "63b2ab420d656600ee5a94b4",
    trelloOrderListId: "63b2ab33782abd0059b3331a",
  },
};

export const trelloConfig = node_env ? trelloConfigLookup[node_env] : undefined;
