import { Route, Routes } from "react-router-dom";
import { ItemEdit } from "./internal/cms/Item";
import { orderPartner } from "../schemata/order";
import PartnerOrderListing from "../components/organisms/ListPartnerOrder";
export default function Outlet() {
  return (
    <Routes>
      <Route index element={<PartnerOrderListing />} />
      <Route path=":id" element={<ItemEdit schema={orderPartner} />} />
    </Routes>
  );
}
