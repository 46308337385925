import { gql, useMutation, useQuery } from "@apollo/client";
import {
  useCreatePurchaseReceiptMutation,
  useDeletePurchaseReceiptMutation,
} from "@app/graphql/pim";
import { useAuth0 } from "@auth0/auth0-react";
import { Listbox, RadioGroup, Switch, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Button } from "../atom/button";
import {
  ArrowDownOnSquareIcon,
  ArrowLeftCircleIcon,
  ArrowRightCircleIcon,
  ArrowUpCircleIcon,
  CheckCircleIcon,
  PencilIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import React, { Fragment, useEffect, useState } from "react";
import { ImageUpload } from "../../components/organisms/ImageUpload";
import Loading from "../molecules/Loading";
import LoadingInline from "../molecules/LoadingInline";
import Popup from "../molecules/Popup";
import { UPDATE } from "./queries";

import { classNames } from "../../utils/dom";

import config from "../../config";

import ZebraDetailList from "../molecules/ZebraDetailList";

import CheckStolenBike from "../molecules/StolenBike";

import { BicyclePriceQuery } from "@app/graphql";
import { SchemaEntry, SummaryEntry } from "@app/types";

import { TextArea, conditionsFromData, typesFromData } from "@app/config";

import { useTranslation } from "react-i18next";

/* eslint-disable react/prop-types */

/* TODO: Clean up these components */

export function queryStatus(bicyclePriceQuery: BicyclePriceQuery) {
  const maps = [
    ["DRAFT", "draft"],
    ["SIGNED", "signed"],
    ["SUBMITTED", "submitted"],
    ["ACCEPTED", "accepted"],
    ["REJECTED", "rejected"],
    ["SIGNATURE_REQUESTED", "signatureRequested"],
    ["HANDED_IN", "handedIn"],
  ];
  return Object.fromEntries(
    maps.map(([status, key]) => [key, bicyclePriceQuery?.status === status])
  );
}

export function queryShippingStatus(bicyclePriceQuery: BicyclePriceQuery) {
  const status = queryStatus(bicyclePriceQuery);

  const maps = [
    [status.handedIn && bicyclePriceQuery?.type == "TRADEIN", "handedIn"],
    [status.accepted, "accepted"],
  ];

  return Object.fromEntries(maps.map(([status, key]) => [key, status]));
}

export const initialFormInventory = {
  name: null,
  description: null,

  details: null,
  score: null,

  price: null,
  status: "DRAFT",
  mechanicName: null,
  conditionGeneral: null,

  mechanicsNotes: null,
  videoDescription: null,
  repairCost: null,
  auctionStart: null,
  auctionEnd: null,
  auctionStartPrice: null,
  priceNew: null,
  locationId: null,
};

export const initialFormInventoryBicycle = {
  electric: false,
  bicycleType: null,
  bicycleSize: null,

  conditionCosmeticFrame: null,
  conditionSteeringStock: null,
  conditionDrive: null,
  conditionCasette: null,
  conditionCrank: null,
  conditionChain: null,
  conditionFrontTire: null,
  conditionRearTire: null,
  conditionFrontWheels: null,
  conditionRearWheels: null,
  conditionBrakes: null,
  conditionDampersFront: null,
  conditionDampersBack: null,
  conditionBattery: null,
  conditionMotor: null,
  mileage: null,
  engineManufacturer: null,
  batteryManufacturer: null,
  spokesId: null,
  riderHeightTo: null,
  riderHeightFrom: null,
  motorNewtonmeter: null,
  batteryDiagnoseSummary: null,
  frameNumber: null,
};

export const initialFormInventorySki = {
  brandId: null,
  skiType: null,
  length: null,
  stiffness: null,
  skins: null,
};

export const initialFormIntake = {
  comment: null,
  estimatedServiceCost: null,
  conditionGeneral: null,
  conditionCosmeticFrame: null,
  conditionHeadset: null,
  conditionCrank: null,
  conditionBottomBracket: null,
  conditionCasette: null,
  conditionChain: null,
  conditionTires: null,
  conditionWheels: null,
  conditionDampersFront: null,
  conditionDampersBack: null,
  conditionBattery: null,
  conditionMotor: null,
  frameNumber: null,
  driveType: "CHAIN",
  bicycleType: null,
  bicycleSize: null,
  conditionBrakes: null,
  conditionWheelsFront: null,
  conditionWheelsBack: null,
  brandMotor: null,
  breakType: null,
  km: null,
  electric: false,
  conditionTiresFront: null,
  conditionTiresBack: null,
  batteryIncluded: false,
  keysIncluded: false,
  keyNumber: null,
  keyBrand: null,
  chargerIncluded: false,
  batteryStatusV: null,
  batteryStatusAh: null,
  batteryStatusWh: null,
};

export const initialFormState = {
  ownerPostalPlace: null,
  ownerPostalCode: null,
  ownerPhone: null,
  ownerFirstName: null,
  ownerLastName: null,
  ownerEmail: null,
  ownerAddress: null,
  frameNumber: null,
  electric: false,
  driveType: "CHAIN",
  conditionWheels: null,
  conditionTires: null,
  conditionHeadset: null,
  conditionGeneral: null,
  conditionCrank: null,
  conditionCosmeticFrame: null,
  conditionChain: null,
  conditionCasette: null,
  conditionBottomBracket: null,
  conditionDampersFront: null,
  conditionDampersBack: null,
  conditionBattery: null,
  conditionMotor: null,
  comment: null,
  bicycleType: null,
  bicycleManualModel: null,
  conditionBrakes: null,
  conditionWheelsFront: null,
  conditionWheelsBack: null,
  brandMotor: null,
  breakType: null,
  km: null,
  conditionTiresFront: null,
  conditionTiresBack: null,
  payoutBankAccount: null,
};

export const findCond = (
  [fieldName, fieldDescrition, options]: SchemaEntry,
  data: any
) => options?.find((condition: any) => condition.id === data[fieldName])?.name;

type BooleanFieldProps = {
  value: boolean;
  onChange: (value: boolean) => void;
  name: string;
  className?: string | undefined;
};

export function BooleanField({
  value,
  onChange,
  name,
  className = "sm:col-span-1 w-32",
}: BooleanFieldProps) {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <Switch.Group as="div" className="flex items-center justify-between">
        <span className="flex flex-grow flex-col">
          <Switch.Label
            as="span"
            className="text-sm font-medium text-gray-900"
            passive
          >
            {t(name)}
          </Switch.Label>
        </span>
        <Switch
          checked={value}
          onChange={(value) => onChange(value)}
          className={classNames(
            value ? "bg-sportgreen" : "bg-gray-200",
            "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-sportgreen focus:ring-offset-2"
          )}
        >
          <span
            aria-hidden="true"
            className={classNames(
              value ? "translate-x-5" : "translate-x-0",
              "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            )}
          />
        </Switch>
      </Switch.Group>
    </div>
  );
}

const GRID_COLS = [
  "sm:grid-cols-1",
  "sm:grid-cols-2",
  "sm:grid-cols-3",
  "sm:grid-cols-4",
  "sm:grid-cols-5",
  "sm:grid-cols-6",
  "sm:grid-cols-7",
  "sm:grid-cols-8",
  "sm:grid-cols-9",
  "sm:grid-cols-10",
  "sm:grid-cols-11",
  "sm:grid-cols-12",
];

type DropDownSelectorProps = {
  value: string;
  values: any[];
  name: string;
  onChange: (value: string) => void;
  className?: string | undefined;
};

export function DropDownSelector({
  value,
  values,
  name,
  onChange,
  className = "grid grid-cols-1 gap-y-6 sm:gap-x-4",
}: DropDownSelectorProps) {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<any | null>(
    values.find((v) => v.id === value)
  );

  const updateValue = (value: any) => {
    setSelected(value);
    onChange(value.id);
  };

  className = className + " " + GRID_COLS[values?.length - 1];

  return (
    <Listbox value={selected} onChange={updateValue}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm font-medium leading-6 text-gray-900">
            {t(name)}
          </Listbox.Label>
          <div className="relative">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
              <span className="block truncate">
                {selected?.name || "Velg merke"}
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {values.map((value) => (
                  <Listbox.Option
                    key={value.id}
                    className={({ active }) =>
                      classNames(
                        active ? "bg-indigo-600 text-white" : "text-gray-900",
                        "relative cursor-default select-none py-2 pl-8 pr-4"
                      )
                    }
                    value={value}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "block truncate"
                          )}
                        >
                          {value.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-indigo-600",
                              "absolute inset-y-0 left-0 flex items-center pl-1.5"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

type ValueSelectorProps = {
  value: string;
  values: any[];
  name: string;
  onChange: (value: string) => void;
  className?: string | undefined;
};

export function ValueSelector({
  value,
  values,
  name,
  onChange,
  className = "grid grid-cols-1 gap-y-6 sm:gap-x-4",
}: ValueSelectorProps) {
  const { t } = useTranslation();
  className = className + " " + GRID_COLS[values?.length - 1];

  return (
    <div>
      <RadioGroup value={value} onChange={(value) => onChange(value)}>
        <RadioGroup.Label className="block text-sm font-medium leading-6 text-gray-900">
          {t(name)}
        </RadioGroup.Label>

        <div className={className}>
          {values.map((item) => (
            <RadioGroup.Option
              key={item.id}
              value={item.id}
              className={({ checked, active }) =>
                classNames(
                  checked ? "border-transparent" : "border-gray-300",
                  active ? "border-sportgreen ring-2 ring-sportgreen" : "",
                  "relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none"
                )
              }
            >
              {({ checked, active }) => (
                <>
                  <div className="flex flex-1">
                    <div className="flex flex-col">
                      <RadioGroup.Label
                        as="span"
                        className="block text-sm font-medium text-gray-900"
                      >
                        {item.name}
                      </RadioGroup.Label>
                    </div>
                  </div>
                  <CheckCircleIcon
                    className={classNames(
                      !checked ? "invisible" : "",
                      "h-5 w-5 text-sportgreen"
                    )}
                    aria-hidden="true"
                  />
                  <div
                    className={classNames(
                      active ? "border" : "border-2",
                      checked ? "border-sportgreen" : "border-transparent",
                      "pointer-events-none absolute -inset-px rounded-lg"
                    )}
                    aria-hidden="true"
                  />
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
}

type EnumSelectorProps = {
  value: string;
  enumValues: object;
  name: string;
  onChange: (value: string) => void;
  className?: string | undefined;
};

export function EnumSelector({
  value,
  enumValues,
  name,
  onChange,
  className,
}: EnumSelectorProps) {
  const values = Object.entries(enumValues).map(([value, key]) => ({
    id: key,
    name: value,
  }));
  return (
    <ValueSelector
      value={value}
      values={values}
      name={name}
      onChange={onChange}
      className={className}
    />
  );
}

type FieldProps = {
  fieldId: string;
  fieldName: string;
  className?: string | undefined;
  type: string;
  value: string | number | boolean | null | undefined;
  disabled?: boolean | undefined;
  required?: boolean | undefined;
  onChange?: (value: string | number | boolean) => void | undefined;
  rows?: number | undefined;
  maxLength?: number | undefined;
  min?: number | undefined;
  max?: number | undefined;
  step?: number | undefined;
  validator?: ((value: string | number | boolean) => string | null) | undefined;
  setValidationError?: ((value: string | null) => void) | undefined;
};

export function Field({
  fieldId,
  fieldName,
  className,
  type,
  value,
  disabled,
  required,
  onChange,
  rows,
  maxLength,
  min,
  max,
  step,
  validator,
  setValidationError,
}: FieldProps) {
  const { t } = useTranslation("no");
  rows = rows ? rows : 3;
  const baseClassNames =
    "block w-full rounded-md border-gray-300 shadow-sm focus:border-sportgreen focus:ring-sportgreen sm:text-sm";
  const disabledClassNames = disabled ? "bg-gray-100 cursor-not-allowed" : "";
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const validate = async (
    value: string | number | boolean | null | undefined
  ) => {
    if (validator && setValidationError && value) {
      //validator returns null if valid, and an error message if not
      if (validator.constructor.name === "AsyncFunction") {
        const error = await validator(value);
        console.log("async", error);
        setErrorMessage(error);
        setValidationError(error);
        return;
      }

      const error = validator(value);
      setErrorMessage(error);
      setValidationError(error);
    } else if (validator && setValidationError) {
      setErrorMessage(null);
      setValidationError(null);
    }
  };
  useEffect(() => {
    validate(value);
  }, []);
  const updateField = (value: any) => {
    validate(value);

    onChange && onChange(value);
  };

  return (
    <div className={className}>
      <label
        htmlFor={fieldId}
        className="block text-sm font-medium leading-6 text-gray-900"
      >
        {fieldName || t(fieldId)}
        {required && <span className="text-red-500">*</span>}
      </label>
      <div className="mt-1">
        {type === "textarea" ? (
          <textarea
            disabled={disabled}
            required={required}
            id={fieldId}
            name={fieldId}
            value={(value as string) || ""}
            onChange={(event) => updateField(event.target.value)}
            rows={rows}
            className={classNames(baseClassNames, "border", disabledClassNames)}
          />
        ) : (
          <input
            disabled={disabled}
            required={required}
            id={fieldId}
            name={fieldId}
            type={type}
            value={(value as string) || ""}
            maxLength={maxLength}
            min={min}
            max={max}
            step={step}
            onChange={(event) =>
              updateField(
                type !== "number" ? event.target.value : +event.target.value
              )
            }
            className={classNames(baseClassNames, disabledClassNames)}
          />
        )}
        {errorMessage && (
          <p className="mt-2 text-sm text-red-600">{errorMessage}</p>
        )}
      </div>
    </div>
  );
}

type FormButtonProps = {
  type: string;
  onClick: () => void;
};

export function FormButton({ type, onClick }: FormButtonProps) {
  const variants = {
    previous: ["Forrige", ArrowLeftCircleIcon],
    next: ["Neste", ArrowRightCircleIcon],
    nextPersonalDetails: ["Gå videre til personalia", ArrowRightCircleIcon],
    requestSignature: ["Send signerings-epost", ArrowUpCircleIcon],
    reopen: ["Gjenåpne", ArrowUpCircleIcon],
    handIn: ["Registrer som innlevert i butikk", ArrowUpCircleIcon],
    accept: ["Aksepter tillbud", CheckCircleIcon],
    save: ["Lage", ArrowDownOnSquareIcon],
    start: ["Registrer mottatt", ArrowRightCircleIcon],
    delete: ["Slett", XCircleIcon],
    reset: ["Reset", XCircleIcon],
  } as { [key: string]: [string, any] };

  const [label, Icon] = variants[type];
  const cs = type === "previous" ? "" : "flex-row-reverse";
  const ics = type === "previous" ? "pl-2" : "pr-2";

  return (
    <Button onClick={onClick} className={cs}>
      <Icon className="h-5 w-5" aria-hidden="true" />
      <span className={ics}>{label}</span>
    </Button>
  );
}

type FormSummaryProps = {
  data: any;
};

export function FormSummary({ data }: FormSummaryProps) {
  const summaries: SummaryEntry[] = [
    {
      name: "Sykkel",
      rows: [
        [
          "Sykkeltype",
          data.bicycleType, //bicycleTypes.find((type) => type.id === data.bicycleType)?.title,
        ],
        ["El-Sykkel?", data.electric ? "Ja" : "Nei"],
      ],
    },

    {
      name: "Tilstand",
      rows: conditionsFromData(data)?.map((condition: SchemaEntry) => [
        condition[1],
        [Number, Boolean].indexOf(condition[2]) !== -1
          ? String(data[condition[0]])
          : findCond(condition, data),
      ]),
    },

    {
      name: "Detaljer",
      rows: [
        ["Rammenummer", data.frameNumber],
        ["Kommentar", data.comment],
        // TODO: Image
      ],
    },

    {
      name: "Personalia",
      rows: [
        ["Fornavn", data.ownerFirstName],
        ["Etternavn", data.ownerLastName],
        ["Epost", data.ownerEmail],
        ["Telefonnummer", data.ownerPhone],
        ["Adresse", data.ownerAddress],
        ["Postnummer", data.ownerPostalCode],
        ["Poststed", data.ownerPostalPlace],
        ["PedalPartner-kode", data.clubCode],
      ],
    },
  ];

  return (
    <>
      {summaries.map(({ name, rows }, i) => (
        <div
          className="mb-6 overflow-hidden bg-white shadow sm:rounded-lg"
          key={i}
        >
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-md font-medium leading-6 text-gray-900">
              {name}
            </h3>
          </div>
          <div className="border-t border-gray-200">
            <dl>
              {rows.map(([label, value], j) => (
                <div
                  className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                  key={j}
                >
                  <dt className="text-sm font-medium text-gray-500">{label}</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {value}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      ))}
    </>
  );
}

type OfferedPriceProps = {
  id: string;
  value: string;
};

export function OfferedPrice({ id, value }: OfferedPriceProps) {
  const [update] = useMutation(UPDATE);
  const [loading, setLoading] = useState(false);
  const [offeredPrice, setOfferedPrice] = useState(value);
  const handleUpdate = () => {
    if (loading) return;
    setLoading(true);

    update({
      variables: { patch: { offeredPrice: parseInt(offeredPrice) }, id: id },
    }).then(({ data }) => {
      setLoading(false);
    });
  };

  return (
    <>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          handleUpdate();
        }}
      >
        <input
          type="number"
          name="offeredPrice"
          value={offeredPrice}
          onChange={(event) => setOfferedPrice(event.target.value)}
          id="offeredPrice"
        />
        <button
          type="submit"
          className="ml-3  inline-flex justify-center rounded-md border border-transparent bg-sportgreen py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-black focus:outline-none focus:ring-2 focus:ring-sportgreen focus:ring-offset-2"
        >
          <ArrowDownOnSquareIcon
            className="h-5 w-5 text-white"
            aria-hidden="true"
          />
        </button>
      </form>
    </>
  );
}

const INTAKE_VALUE_CALC_QUERY = gql`
  query BicycleIntakeFormQuery($id: UUID!) {
    bicyclePriceQuery(id: $id) {
      id
      status
      type
      org {
        id
        name
      }

      offeredPrice
      retailPriceNok
      depreciatedValue
      brandBonus
      typeBonus
      electricBonus
      calculatedSportiendaScore
      scoreDiscount
      discountDampersFront
      discountDampersBack
      discountBattery
      discountMotor
      currentPrice
      calculatedOfferPrice
      receiptUrl
      contractUrl
      clubCode
      bicycle {
        id
        year
        model {
          id
          brandFamily {
            id
            brand {
              id
              name
            }
          }
        }
        price
        currency
      }
    }
  }
`;

type ValuCalculationsProps = {
  bicyclePriceQueryId: string;
};

function ValuCalculations({ bicyclePriceQueryId }: ValuCalculationsProps) {
  const { user } = useAuth0();
  const { loading, refetch, data } = useQuery(INTAKE_VALUE_CALC_QUERY, {
    variables: { id: bicyclePriceQueryId },
  });
  if (user?.org_id !== process.env.REACT_APP_SPORTIENDA_ORG) {
    return null;
  }
  if (loading) {
    return <Loading />;
  }

  if (!data) {
    return <></>;
  }
  const priceQuery = data?.bicyclePriceQuery;

  return (
    <ZebraDetailList
      inline
      rows={[
        ["Type", priceQuery?.type],
        ["Org", priceQuery?.org?.name],
        [`PedalPartner-kode`, priceQuery?.clubCode],
        ["Status", priceQuery.status],
        [
          `Nypris (${priceQuery?.bicycle?.year})`,
          `${priceQuery?.bicycle?.price} ${priceQuery?.bicycle?.currency}`,
        ],
        [
          `Nypris NOK (${priceQuery?.bicycle?.year})`,
          `${priceQuery?.retailPriceNok} NOK`,
        ],
        [`Nåpris`, `${priceQuery?.depreciatedValue} NOK`],
        [
          `Brand bonus faktor (${priceQuery?.bicycle?.model?.brandFamily?.brand?.name})`,
          priceQuery?.brandBonus,
        ],
        [`Type bonus`, priceQuery?.typeBonus],
        [`Electric bonus`, priceQuery?.electricBonus],
        [`SportiendaScore`, priceQuery?.calculatedSportiendaScore],
        [`Score fratrekk faktor`, priceQuery?.scoreDiscount],
        [`Fratrekk depere forran`, priceQuery?.discountDampersFront],
        [`Fratrekk dempere bak`, priceQuery?.discountDampersBack],
        [`Fratrekk batteri`, priceQuery?.discountBattery],
        [`Fratrekk motor`, priceQuery?.discountMotor],
        [`Nåverdi`, priceQuery?.currentPrice],
        [`Beregnet tilbud til kunde`, priceQuery?.calculatedOfferPrice],

        [
          "Offered Price",
          <>
            {(priceQuery?.status === "SUBMITTED" ||
              priceQuery?.status === "ACCEPTED") &&
            user?.org_id === process.env.REACT_APP_SPORTIENDA_ORG ? (
              <OfferedPrice
                value={priceQuery.offeredPrice}
                id={priceQuery.id}
              />
            ) : (
              <>{priceQuery.offeredPrice}</>
            )}
          </>,
        ],
      ]}
    />
  );
}

type BicyclePriceQueryKeyType = BicyclePriceQuery & {
  [key: string]: any;
};

type SubmittedInfoProps = {
  bicyclePriceQuery: BicyclePriceQueryKeyType;
};

function UpdateRecieptPopup({
  bicyclePriceQuery,
}: {
  bicyclePriceQuery: BicyclePriceQueryKeyType;
}) {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [receiptUrl, setreceiptUrl] = useState<string | undefined>();
  const [uploadImage] = useCreatePurchaseReceiptMutation();
  const [deleteImage] = useDeletePurchaseReceiptMutation();

  const id = bicyclePriceQuery.id;
  const onImageChange = async (images: any[]) => {
    try {
      setLoading(true);

      for (const img of images) {
        try {
          const result = await uploadImage({
            variables: {
              bicyclePriceQueryId: id,
            },
          });
          const uploadUrl = result?.data?.createPurchaseReceipt?.uploadURL;

          if (uploadUrl) {
            const myHeaders = new Headers({ "Content-Type": img.file.type });
            const response = await fetch(uploadUrl, {
              method: "PUT",
              headers: myHeaders,
              body: img.file,
            });
          }
          setreceiptUrl(URL.createObjectURL(img));
        } catch (e) {
          // TODO: Error message
          console.error(e);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const onImageRemove = async (image: any) => {
    if (image) {
      if (window.confirm("Are you sure you want to delete this image?")) {
        await deleteImage({
          variables: {
            bicyclePriceQueryId: id,
          },
        });
        setreceiptUrl(undefined);
      }
    }
  };

  const imageList: any[] = [];
  if (receiptUrl) {
    imageList.push({ preview: new URL(receiptUrl) });
  }
  return (
    <>
      <Popup open={open} setOpen={setOpen} title="Rediger sykkel">
        <div className="min-h-64 h-80">
          <ImageUpload
            imageList={imageList}
            onChange={onImageChange}
            onRemoved={onImageRemove}
            label="Kjøpsdokumentasjon (Originalkvittering eller lignende)"
            required={true}
            multiple={false}
          />
        </div>
      </Popup>
      {loading && <LoadingInline />}

      <button
        className="text-base text-sportblack"
        onClick={() => setOpen(!open)}
      >
        <PencilIcon className="h-4 w-4 inline-block" />
      </button>
    </>
  );
}

export function SubmittedInfo({ bicyclePriceQuery }: SubmittedInfoProps) {
  const { user } = useAuth0();

  return (
    <>
      {bicyclePriceQuery?.status !== "DRAFT" && (
        <div className="mb-6 bg-white sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-md font-medium leading-6 text-gray-900">
              Status
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              {bicyclePriceQuery?.status === "SUBMITTED" && (
                <>Insendt, venter på behandling av sportienda</>
              )}
              {bicyclePriceQuery?.status === "SIGNATURE_REQUESTED" && (
                <>Venter på signering av kunde</>
              )}
              {bicyclePriceQuery?.status === "SIGNED" && <>Signert</>}
              {bicyclePriceQuery?.status === "HANDED_IN" && <>Mottatt butikk</>}
            </div>
          </div>
        </div>
      )}

      <div className="mb-6 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Sykkel
          </h3>
        </div>

        <ZebraDetailList
          inline
          rows={typesFromData(bicyclePriceQuery)?.map((condition) => [
            condition[1],

            [Number, Boolean].indexOf(condition[2]) !== -1
              ? String(bicyclePriceQuery[condition[0]])
              : findCond(condition, bicyclePriceQuery),
          ])}
        />
      </div>

      <div className="mb-6 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Tilstand
          </h3>
        </div>
        <ZebraDetailList
          inline
          rows={conditionsFromData(bicyclePriceQuery)?.map((condition) => [
            condition[1],
            // [Number, Boolean].indexOf(condition[2]),
            // " - " +
            [Number, Boolean].indexOf(condition[2]) !== -1
              ? String(bicyclePriceQuery[condition[0]])
              : findCond(condition, bicyclePriceQuery),
          ])}
        />
      </div>

      <div className="mb-6 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Bilder og detaljer
          </h3>
        </div>
        <ZebraDetailList
          inline
          rows={[
            ["Rammenummer", bicyclePriceQuery.frameNumber],
            ["Kommentar", bicyclePriceQuery.comment],
          ]}
        />
      </div>

      <div className="mb-6 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Personalia
          </h3>
        </div>
        <ZebraDetailList
          inline
          rows={[
            ["Fornavn", bicyclePriceQuery.ownerFirstName],
            ["Etternavn", bicyclePriceQuery.ownerLastName],
            ["Epost", bicyclePriceQuery.ownerEmail],
            ["Telefonnummer", bicyclePriceQuery.ownerPhone],
            ["Adresse", bicyclePriceQuery.ownerAddress],
            ["Postnummer", bicyclePriceQuery.ownerPostalCode],
            ["Poststed", bicyclePriceQuery.ownerPostalPlace],
            [
              "Kvittering",
              <div className="flex gap-4 items-center">
                {bicyclePriceQuery.receiptUrl ? (
                  <a
                    target="_blank"
                    className="underline"
                    href={bicyclePriceQuery.receiptUrl}
                  >
                    Last ned kvitering
                  </a>
                ) : (
                  "ingen kvitering"
                )}
                <UpdateRecieptPopup bicyclePriceQuery={bicyclePriceQuery} />
              </div>,
            ],
          ]}
        />
      </div>
      {user !== undefined && user?.org_id === config.org && (
        <div className="mb-6 overflow-hidden bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-md font-medium leading-6 text-gray-900">
              Price Information (only visible for Sportienda)
            </h3>
          </div>
          <ValuCalculations bicyclePriceQueryId={bicyclePriceQuery.id} />
        </div>
      )}
      <CheckStolenBike bicyclePriceQueryId={bicyclePriceQuery.id} />

      <div className="mb-6 overflow-hidden bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-md font-medium leading-6 text-gray-900">
            Vedlegg
          </h3>
        </div>
        <div className="border-t border-gray-200">
          {bicyclePriceQuery?.fikenPurchaseId && (
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <a
                className="text-sportgreen"
                target="_blank"
                rel="noreferrer"
                href={`https://fiken.no/foretak/${process.env.REACT_APP_FIKEN_COMPANY_SLUG}/handel/kjop/${bicyclePriceQuery.fikenPurchaseId}`}
              >
                Fiken vedlegg
              </a>
            </div>
          )}
          {(bicyclePriceQuery?.status === "SIGNED" ||
            bicyclePriceQuery?.status === "HANDED_IN") && (
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <a
                className="text-sportgreen"
                target="_blank"
                rel="noreferrer"
                href={bicyclePriceQuery?.contractUrl || "#"}
              >
                Kontrakt
              </a>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

type FormFieldsProps = {
  schemaFunction: (data: any) => SchemaEntry[];
  data: any;
  updateField: (name: string) => (value: any) => void;
};

export function FormFields({
  schemaFunction,
  data,
  updateField,
}: FormFieldsProps) {
  const fields = schemaFunction(data);
  return (
    <div className="mt-8 w-full">
      {fields.map(([name, label, cond, required, validator]) => {
        if (cond === Boolean) {
          return (
            <div
              className="mt-8 mb-8 p-4 border border-gray-300 shadow-sm rounded-lg bg-green-100 "
              key={name}
            >
              <BooleanField
                name={label}
                value={data[name]}
                onChange={updateField(name)}
              />
            </div>
          );
        } else if (cond === Number) {
          return (
            <div className="mt-4" key={name}>
              <Field
                fieldId={name}
                fieldName={label}
                value={data[name]}
                type="number"
                required={required}
                onChange={updateField(name)}
                validator={validator}
              />
            </div>
          );
        } else if (cond === TextArea) {
          return (
            <div className="mt-4" key={name}>
              <Field
                fieldId={name}
                fieldName={label}
                value={data[name]}
                type="textarea"
                required={required}
                className="sm:col-span-3"
                onChange={updateField(name)}
                validator={validator}
              />
            </div>
          );
        } else if (cond === String) {
          return (
            <div className="mt-4" key={name}>
              <Field
                fieldId={name}
                fieldName={label}
                value={data[name]}
                type="text"
                required={required}
                onChange={updateField(name)}
                validator={validator}
              />
            </div>
          );
        } else {
          return (
            <div className="mt-4" key={name}>
              <ValueSelector
                value={data[name]}
                onChange={updateField(name)}
                name={label}
                values={cond}
              />
            </div>
          );
        }
      })}
    </div>
  );
}

export function FormSection({
  children,
  title,
  description,
  buttons,
  gridCols = 1,
}: {
  children: React.ReactNode;
  title: string;
  gridCols?: number;
  description?: string | undefined;
  buttons?: React.ReactNode | undefined;
}) {
  return (
    <div className="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-5">
      <div className="px-4 sm:px-0">
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          {title}
        </h2>
        {description && (
          <p className="mt-1 text-sm leading-6 text-gray-600">{description}</p>
        )}
      </div>

      <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-4">
        <div className="px-4 py-6 sm:p-8">
          <div
            className={`grid grid-cols-${gridCols} gap-x-6 gap-y-8  md:col-span-2`}
          >
            {children}
          </div>
        </div>
        {buttons && (
          <div className="flex items-center justify-end gap-x-6 border-t border-gray-900/10 px-4 py-4 sm:px-8">
            {buttons}
          </div>
        )}
      </div>
    </div>
  );
}
