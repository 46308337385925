import {
  FieldType,
  Form,
  ItemFilter,
  ItemSchema,
  ListColumn,
} from "@app/types";
import { z } from "zod";
import { dateTimeFormat } from "../utils/dates";
import { getOptionsFromEnum } from "../utils/schema";
import {
  ObjectType,
  InventoryStatus,
  InventoryType,
  InventoriesOrderBy,
  useCreateInventoryMutation,
  usePimInventoriesQuery,
  usePimInventoryQuery,
  useUpdateInventoryMutation,
} from "@app/graphql/pim";

export const addInitialValues = {
  name: "",
  description: "",
  details: "",
  mechanicName: "",
  mechanicsNotes: "",
  repairCost: 0,
  conditionGeneral: null,
  videoDescription: "",
  auctionStart: null,
  auctionEnd: null,
  auctionStartPrice: 0,
  status: InventoryStatus.Draft,
  price: 0,
  priceNew: 0,
  score: 0,
  locationId: null,
};

export const addForm: Form = [
  {
    id: "name",
    name: "Tittel",
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "description",
    name: "Description",
    type: FieldType.textarea,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "details",
    name: "Spesifikasjoner",
    type: FieldType.textarea,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "mechanicName",
    name: "Mekaniker fornavn",
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "mechanicsNotes",
    name: "Mekanikers notater",
    type: FieldType.textarea,
    required: false,
    zFieldType: z.string().optional(),
  },
  {
    id: "repairCost",
    name: "Reparasjonskostnad (ihht faktura)",
    type: FieldType.number,
    required: false,
    zFieldType: z.number().optional(),
  },
  {
    id: "locationId",
    name: "Location",
    type: FieldType.custom,
    required: false,
    zFieldType: z.string().uuid().nullable(),
    fieldProps: {
      component: "LocationSelector",
      name: "Location",
    },
  },
  {
    id: "conditionGeneral",
    name: "Generell vurdering",
    type: FieldType.select,
    required: true,
    zFieldType: z.number(),
    values: [
      { name: "God stand", id: 2 },
      { name: "Meget god stand", id: 3 },
      { name: "Nytt", id: 4 },
    ],
  },
  {
    id: "videoDescription",
    name: "Video Description",
    type: FieldType.textarea,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "auctionStart",
    name: "Auksjon start",
    type: FieldType.datetime,
    required: false,
    zFieldType: z.date().optional(),
  },
  {
    id: "auctionEnd",
    name: "Auksjon slutt",
    type: FieldType.datetime,
    required: false,
    zFieldType: z.date().optional(),
  },
  {
    id: "auctionStartPrice",
    name: "Auksjon utropspris",
    type: FieldType.number,
    required: false,
    zFieldType: z.number().optional(),
  },
  {
    id: "status",
    name: "Status",
    type: FieldType.select,
    required: true,
    zFieldType: z.nativeEnum(InventoryStatus),
    values: getOptionsFromEnum(InventoryStatus),
  },
  {
    id: "price",
    name: "Pris",
    type: FieldType.number,
    required: true,
    zFieldType: z.number(),
  },
  {
    id: "priceNew",
    name: "Nypris",
    type: FieldType.number,
    required: true,
    zFieldType: z.number(),
  },
  {
    id: "score",
    name: "Sales Score",
    type: FieldType.number,
    required: true,
    zFieldType: z.number(),
  },
];

export const editForm = addForm;

export const listColumns: ListColumn[] = [
  { id: "name", name: "Tittel" },
  { id: "status", name: "Status" },
  { id: "type", name: "Type" },
  { id: "objectType", name: "Objekttype" },
  { id: "price", name: "Pris" },
  {
    id: "locationId",
    name: "Location",
    parseExtraData: (data) => data.location?.name || "-",
  },
  {
    id: "soldByOrgId",
    name: "Partner",
    parseExtraData: (data) => data.soldByOrg?.name || "-",
  },
];

// For the inventory schema, we'll use uuid filters for now
// The actual dropdown implementation with options will be handled in the UI components

const filters: ItemFilter[] = [
  {
    name: "status",
    type: "enum",
    enum: InventoryStatus,
    displayName: "Status",
  },
  {
    name: "locationId",
    type: "select",
    placeholder: "Filter by location...",
    displayName: "Location",
    defaultEmpty: true,
  },
  {
    name: "soldByOrgId",
    type: "select",
    placeholder: "Filter by partner...",
    displayName: "Partner",
    defaultEmpty: true,
  },
];

const inventory: ItemSchema = {
  id: "inventory",
  name: "Inventory",
  plural: "Inventories",
  listColumns,
  addForm,
  editForm,
  addInitialValues,
  editInitialValues: addInitialValues,
  filters,
  defaultOrderBy: InventoriesOrderBy.CreatedDesc,
  useItemsQuery: usePimInventoriesQuery,
  useItemQuery: usePimInventoryQuery,
  useCreateItemMutation: useCreateInventoryMutation,
  useUpdateItemMutation: useUpdateInventoryMutation,
  parseItem: (data) => data?.inventory || {},
  parseItems: (data) => ({
    totalCount: data?.inventories?.totalCount,
    items: data?.inventories?.nodes,
  }),
  createInputFromValues: (values) => ({
    inventory: values,
  }),
  createAddInputFromValues: (values) => values,
};

export default inventory;
