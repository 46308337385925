import { Suspense, lazy, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { SportiendaOrgProvider } from "./hooks/org";
import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { ApolloProvider } from "@apollo/client/react";
import { AppState, Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import Loading from "./components/molecules/Loading";
import Header from "./components/organisms/Navbar";
import Sidebar from "./components/organisms/Sidebar";
import Home from "./pages/Home";
import { TradeInWrapper } from "./pages/SellForm";
import InternalPim from "./pages/internal/InternalPim";
import TradeInPage from "./pages/TradeInPage";
import { ItemEdit } from "./pages/internal/cms/Item";
import { ItemList } from "./pages/internal/cms/List";

import PartnerInventory from "./pages/PartnerInventoryPage";
import PartnerOrder from "./pages/PartnerOrderPage";
import { SidebarLayout } from "./components/atom/sidebar-layout";
import { partnerCMS } from "./schemata";

const NotFound = lazy(() => import("./components/organisms/notfound"));

function RedirectToInternal() {
  const location = useLocation();
  const { pathname, search } = location;

  // Check if the path already starts with '/internal'
  if (pathname.startsWith("/internal")) {
    // Optionally render a 404 Not Found page or redirect to a default internal route
    return <NotFound />;
  }

  // Redirect to the internal path
  return <Navigate to={`/internal${pathname}${search}`} replace />;
}
export function Pim() {
  return (
    <BrowserRouter>
      <SidebarLayout sidebar={<Sidebar />} navbar={<Header />}>
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route index element={<Home />} />
            <Route path="tradein">
              <Route index element={<TradeInPage />} />
              <Route path=":id" element={<TradeInWrapper />} />
            </Route>

            <Route path="partner/*" element={<PartnerInventory />} />
            <Route path="order/*" element={<PartnerOrder />} />
            <Route path="internal/*" element={<InternalPim />} />
            {partnerCMS.map((schema) => (
              <Route key={schema.id} path={schema.id}>
                <Route index element={<ItemList schema={schema} />} />
                <Route path=":id" element={<ItemEdit schema={schema} />} />
              </Route>
            ))}
            <Route path="*" element={<RedirectToInternal />} />
          </Routes>
        </Suspense>
      </SidebarLayout>
    </BrowserRouter>
  );
}
export function ApolloAndAuthWrapper({
  children,
}: {
  children: React.ReactNode;
}) {
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();

  const httpLink = createHttpLink({
    uri: process.env.REACT_APP_PIM_API_URL,
  });

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them

    let token;
    try {
      token = await getAccessTokenSilently();
    } catch (error) {
      console.error(error);
    }
    if (!token) return headers;
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    credentials: "include",
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });
  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: { returnTo: window.location.pathname },
    });
    return <></>;
    // return <><button onClick={()=> loginWithRedirect()}>login</button></>
  }
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}

export default function App() {
  const navigate = (url: string) => {
    window.history.replaceState({}, document.title, url);
  };

  const onRedirectCallback = (appState: AppState | undefined) => {
    navigate(
      appState?.returnTo || appState?.targetUrl || window.location.pathname
    );
  };

  if (
    !process.env.REACT_APP_AUTH0_DOMAIN ||
    !process.env.REACT_APP_AUTH0_APP_IDENTIFIER_PIM ||
    !process.env.REACT_APP_API_PIM_AUDIENCE
  ) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="text-2xl font-semibold">
          Missing Auth0 env variables
        </div>
      </div>
    );
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_APP_IDENTIFIER_PIM}
      authorizationParams={{
        redirectUri: `${window.location.origin}`,
        scope:
          "admin read:current_user user_id email org_id update:current_user_metadata",
        audience: process.env.REACT_APP_API_PIM_AUDIENCE,
        onRedirectCallback: onRedirectCallback,
      }}
    >
      <ApolloAndAuthWrapper>
        <SportiendaOrgProvider>
          <Pim />
        </SportiendaOrgProvider>
      </ApolloAndAuthWrapper>
    </Auth0Provider>
  );
}
