import { useAuth0 } from "@auth0/auth0-react";
import { Route, Routes } from "react-router-dom";
import { internalCMS } from "../../schemata";
import PriceQueryDetails from "./PriceQueries/PriceQueryDetails";
import PriceQueryList from "./PriceQueries/PriceQueryList";
import Protocol from "./Protocol";

import StatsPurchase from "./StatsPurchase";
import Statistics from "./statistics/Statistics";
import CMS from "./cms/Cms";
import { ItemEdit } from "./cms/Item";
import { ItemList } from "./cms/List";
import IntakeDetails from "./intake/IntakeDetails";
import IntakeList from "./intake/IntakeLists";
import InventoryDetails from "./inventory/InventoryDetails";
import ValueByYearList from "./valueByYear/valueByYearList";
import TrelloPage from "./Trello";
export default function InternalPim() {
  const { user } = useAuth0();
  if (user?.org_id !== process.env.REACT_APP_SPORTIENDA_ORG) {
    return null;
  }
  return (
    <Routes>
      <Route path="trello">
        <Route index element={<TrelloPage />} />
      </Route>
      <Route path="statistics/*" element={<Statistics />} />

      <Route path="pricequery">
        <Route index element={<PriceQueryList type={null} />} />
        <Route path=":id" element={<PriceQueryDetails />} />
      </Route>

      <Route path="protocol">
        <Route index element={<Protocol />} />
      </Route>

      <Route path="intake">
        <Route index element={<IntakeList />} />
        <Route path=":id" element={<IntakeDetails />} />
      </Route>

      <Route path="cms/*" element={<CMS />} />
      {/* Keep the inventory details route but remove the custom listing */}
      <Route path="inventory/:id" element={<InventoryDetails />} />

      <Route path="value-by-year">
        <Route index element={<ValueByYearList />} />
      </Route>
      {internalCMS.map((schema) => (
        <Route key={schema.id} path={schema.id}>
          <Route index element={<ItemList schema={schema} />} />
          <Route path=":id" element={<ItemEdit schema={schema} />} />
        </Route>
      ))}
    </Routes>
  );
}
