import type { ItemSchema } from "@app/types";
import addOnProduct from "./addonproduct";
import associatedClub from "./associated_club";
import bicycle from "./bicycle";
import brand from "./brand";
import brandFamily from "./brand_family";
import discountCode from "./discount_code";
import location from "./location";
import model from "./model";
import order from "./order";
import org from "./org";
import product from "./product";
import user from "./user";
import inventory from "./inventory";
export const cms: ItemSchema[] = [
  user,
  org,
  location,
  brand,
  brandFamily,
  model,
  bicycle,

  product,
  addOnProduct,
  discountCode,
  associatedClub,
];

export const internalCMS: ItemSchema[] = [order, inventory];
export const partnerCMS: ItemSchema[] = [];

export default cms;
