import React, { useEffect, useState } from "react";
import { useLocationsQuery, useLocationOptionsQuery, useOrgOptionsQuery } from "@app/graphql/pim";

type LocationOption = {
  id: string;
  name: string;
};

type LocationSelectorProps = {
  value: string | null;
  onChange: (value: string | null) => void;
  name?: string;
  className?: string;
  soldByOrgId?: string | null;
};

export const LocationSelector: React.FC<LocationSelectorProps> = ({
  value,
  onChange,
  name = "Location",
  className = "",
  soldByOrgId = null,
}) => {
  const [options, setOptions] = useState<LocationOption[]>([]);
  const { data, loading } = useLocationsQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data?.locations?.nodes) {
      // Filter locations on the client side
      const filteredLocations = data.locations.nodes.filter(
        (location) =>
          location.isPublic &&
          (location.isGlobal ||
            (soldByOrgId && location.ownerOrgId === soldByOrgId))
      );

      const locationOptions = filteredLocations.map((location) => ({
        id: location.id,
        name: location.name,
      }));

      setOptions([
        { id: "", name: "-- Select a location --" },
        ...locationOptions,
      ]);
    }
  }, [data, soldByOrgId]);

  return (
    <div className={`${className}`}>
      <label
        htmlFor="location"
        className="block text-sm font-medium text-gray-700"
      >
        {name}
      </label>
      <select
        id="location"
        name="location"
        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        value={value || ""}
        onChange={(e) =>
          onChange(e.target.value === "" ? null : e.target.value)
        }
        disabled={loading}
      >
        {options.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export function useLocationOptions() {
  const { data, loading } = useLocationOptionsQuery();
  
  const options = React.useMemo(() => {
    return (data?.locations?.nodes || []).map(node => ({
      id: node.id,
      name: node.name || ''
    }));
  }, [data]);
  
  return { options, loading };
}

export function useOrgOptions() {
  const { data, loading } = useOrgOptionsQuery();
  
  const options = React.useMemo(() => {
    return (data?.orgs?.nodes || []).map(node => ({
      id: node.id,
      name: node.name || ''
    }));
  }, [data]);
  
  return { options, loading };
}

export default LocationSelector;
