import { Listbox, Transition } from "@headlessui/react";
import { ChevronUpDownIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";
import { Field, Label } from "../atom/fieldset";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { classNames } from "../../utils/dom";
import { getOptionsFromEnum } from "../../utils/schema";
import { Select } from "../atom/select";
export default function FilterSingle({
  name,
  values = [],
  displayName,
}: {
  name: string;
  values: any[];
  displayName?: string;
}) {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const paramValue = searchParams.get(name) || "all";
  const options = [{ id: "all", name: t("All") }, ...flattenNodes(values)];
  const selected = options.find((option) => String(option.id) === paramValue);

  const handleChange = (event: any) => {
    const id = event.target.value;
    id === "all" ? searchParams.delete(name) : searchParams.set(name, id);
    searchParams.delete("offset");
    setSearchParams(searchParams);
  };
  return (
    <div className="mr-3 mb-3">
      <div className="text-xs text-gray-500 mb-1">{displayName || t(name)}</div>
      <Select onChange={handleChange} value={selected?.id}>
        {options.map((option, key) => (
          <option key={key} value={option.id}>
            {t(option.name)}
          </option>
        ))}
      </Select>
    </div>
  );
}

function flattenNodes(nodes: any[]) {
  return nodes.reduce((prev: any, current: any) => {
    const { id, name, parentId, children } = current;
    prev.push({ id, name, parentId });
    children?.nodes?.forEach((node: any) => {
      const { id, name, parentId } = node;
      prev.push({ id, name, parentId });
    });
    return prev;
  }, []);
}

export function FilterEnum({
  name,
  enumValues,
  displayName,
}: {
  name: string;
  enumValues: any;
  displayName?: string;
}) {
  const values = getOptionsFromEnum(enumValues);
  return <FilterSingle name={name} values={values} displayName={displayName} />;
}
