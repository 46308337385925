"use client";
import { useShipMutation } from "@app/graphql/pim";
import { Order as OrderType } from "@app/graphql/types";
import {
  FieldType,
  FormFieldComponentProps,
  Form as FormType,
} from "@app/types";
import { dateTimeFormat } from "../../../utils/dates";

import { useState } from "react";

import Form, { DialogForm } from "../../../components/organisms/CMSForm";
// import {FormWrapper} from "../../../components/organisms/CMSFormWrapper";

export default function ShippingForm({
  id,
  name,
  data,
  refetch,
  dialog,
  description,
}: FormFieldComponentProps) {
  const order = data as OrderType;
  const [loading, setLoading] = useState(false);
  const [ship] = useShipMutation();
  const formData = {
    shippingReference: null,
    shippingTime: null,
    ourShippingCost: null,
  };
  const dictionary = {};

  const save = (data: any) => {
    if (!order || !data.shippingReference || !data.ourShippingCost) {
      alert("Please fill in all required fields");
    }
    setLoading(true);
    ship({
      variables: {
        orderId: order.id,
        shippingReference: data.shippingReference,
        shippingCost: parseInt(data.ourShippingCost),
        shippingTime: data.shippingTime,
      },
    })
      .then(() => {
        setLoading(false);
        refetch?.();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const shipingForm: FormType = [
    {
      id: "shippingReference",
      name: "Shipping Reference",
      type: FieldType.text,
      colspan: 2,
      required: true,
    },
    {
      id: "ourShippingCost",
      name: "Shipping Cost",
      type: FieldType.number,
      colspan: 2,
      required: true,
    },
    {
      id: "shippingTime",
      name: "Shipping Time (optional)",
      type: FieldType.datetime,
      colspan: 2,
      required: false,
    },
  ];

  const shipingShippedForm: FormType = [
    {
      id: "shippingReference",
      name: "Shipping Reference",
      type: FieldType.label,
      colspan: 2,
      required: true,
    },
    {
      id: "ourShippingCost",
      name: "Shipping Cost",
      type: FieldType.label,
      colspan: 2,
      required: true,
    },
    {
      id: "shippingTime",
      name: "Shipping Time",
      type: FieldType.label,
      colspan: 2,
      required: false,
      parseFormData: (data) => dateTimeFormat(data),
    },
  ];
  if (!order || (order.status !== "PAID" && order.status !== "SHIPPED")) {
    return null;
  }
  if (dialog) {
    return (
      <DialogForm
        title={name}
        form={shipingForm}
        data={formData}
        extraData={order}
        dictionary={dictionary}
        loading={loading}
        itemId={"order"}
        save={save}
        description={description}
        actionName={"Ship and capture payment"}
      />
    );
  }
  return (
    <div className="border bg-sportlightgrey rounded p-4 my-4">
      <h3 className="text-lg font-medium leading-6 mb-4 text-gray-900">
        {name}
      </h3>
      {order?.shippingReference ? (
        <Form
          title={"Shipping Details"}
          form={shipingShippedForm}
          data={formData}
          extraData={order}
          dictionary={dictionary}
          loading={loading}
          itemId={"order"}
        />
      ) : (
        <Form
          title={"Shipping Details"}
          form={shipingForm}
          data={formData}
          extraData={order}
          save={save}
          dictionary={dictionary}
          loading={loading}
          itemId={"order"}
          actionName={"Ship and capture payment"}
        />
      )}
    </div>
  );
}
