import {
  useInventoryAnalyticsQuery,
  InventoryAnalyticsOrderBy,
} from "@app/graphql/pim";
import { Link } from "react-router-dom";
import { classNames, formatCurrency, createImageUrl } from "../../utils/dom";
export default function PartnerInventoryAnalytics() {
  const { data, loading } = useInventoryAnalyticsQuery({
    variables: {
      orderBy: InventoryAnalyticsOrderBy.EventCountDesc,
      pagesize: 12,
    },
  });
  console.log("inventoryana", data);
  const inventories = data?.inventoryAnalytics?.nodes || [];

  return (
    <div className="bg-white shadow sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Populære produkter
        </h3>

        <ul
          role="list"
          className="grid grid-cols-3 gap-x-4 gap-y-8 sm:grid-cols-4 sm:gap-x-6 lg:grid-cols-6 xl:gap-x-8"
        >
          {inventories.map((inventory) => (
            <li key={inventory.inventoryId} className="relative">
              <div className="group overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img
                  alt={inventory.inventoryName || "Inventory image"}
                  src={
                    inventory.imageId
                      ? createImageUrl(inventory.imageId)
                      : "https://sportienda.com/bike.svg"
                  }
                  className="pointer-events-none aspect-[16/9] object-cover group-hover:opacity-75"
                />
                <Link
                  type="button"
                  to={`/partner/${inventory.inventoryId}`}
                  className="absolute inset-0 focus:outline-none"
                >
                  <span className="sr-only">
                    View details for {inventory.inventoryName}
                  </span>
                </Link>
              </div>
              <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
                {inventory.inventoryName}
              </p>
              <p className="pointer-events-none block text-sm font-medium text-gray-500">
                Visninger: {inventory.eventCount}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
