import { Route, Routes } from "react-router-dom";
import SearchAnalytics from "./SearchAnalytics";
import StatsPurchase from "../StatsPurchase";
import { StackedContent } from "../../../components/atom/content";
import { Heading } from "../../../components/atom/heading";
import { Link } from "react-router-dom";
import { MagnifyingGlassIcon, ShoppingBagIcon } from "@heroicons/react/20/solid";

export default function Statistics() {
  return (
    <Routes>
      <Route index element={<StatisticsHome />} />
      <Route path="search" element={<SearchAnalytics />} />
      <Route path="purchase" element={<StatsPurchase />} />
    </Routes>
  );
}

function StatisticsHome() {
  const statisticsLinks = [
    {
      name: "Search Analytics",
      description: "View analytics for search terms used by customers",
      icon: MagnifyingGlassIcon,
      href: "/internal/statistics/search",
    },
    {
      name: "Purchase Statistics",
      description: "View statistics for purchases",
      icon: ShoppingBagIcon,
      href: "/internal/statistics/purchase",
    },
  ];

  return (
    <StackedContent>
      <Heading>Statistics</Heading>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
        {statisticsLinks.map((link) => (
          <div
            key={link.name}
            className="relative flex items-center space-x-3 rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400"
          >
            <div className="flex-shrink-0">
              <link.icon className="h-10 w-10 text-indigo-600" aria-hidden="true" />
            </div>
            <div className="min-w-0 flex-1">
              <Link to={link.href} className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true" />
                <p className="text-sm font-medium text-gray-900">{link.name}</p>
                <p className="truncate text-sm text-gray-500">{link.description}</p>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </StackedContent>
  );
}