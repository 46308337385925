import {
  useLocationsQuery,
  useLocationQuery,
  useUpdateLocationMutation,
  useCreateLocationMutation,
} from "@app/graphql/pim";
import { FieldType, Form, ItemSchema, ListColumn } from "@app/types";
import { z } from "zod";

// Define enum for place_type options
const placeTypeOptions = [
  { id: "showroom", name: "Showroom" },
  { id: "warehouse", name: "Warehouse" },
  { id: "partner", name: "Partner" },
  { id: "other", name: "Other" },
];

// Define options for prepositions
const prepositionOptions = [
  { id: "at", name: "at (hos)" },
  { id: "in", name: "in (i)" },
  { id: "from", name: "from (fra/från)" },
  { id: "by", name: "by (ved/vid)" },
  { id: "near", name: "near (nær/nära)" },
];

export const addInitialValues = {
  id: null,
  name: "",
  address: null,
  postalCode: null,
  postalPlace: null,
  preposition: "at", // Default preposition
  placeType: "partner",
  isGlobal: false,
  isPublic: false,
  ownerOrgId: null,
  description: null,
  tradeInForm: null,
  testRideForm: null,
  googlePlaceId: null,
};

export const addForm: Form = [
  {
    id: "name",
    name: "Location Name",
    colspan: 4,
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "address",
    name: "Address",
    colspan: 4,
    type: FieldType.text,
    required: false,
    zFieldType: z.string().nullable(),
  },
  {
    id: "postalCode",
    name: "Postal Code",
    colspan: 2,
    type: FieldType.text,
    required: false,
    zFieldType: z.string().nullable(),
  },
  {
    id: "postalPlace",
    name: "City/Place",
    colspan: 2,
    type: FieldType.text,
    required: false,
    zFieldType: z.string().nullable(),
  },
  {
    id: "preposition",
    name: "Preposition",
    colspan: 2,
    type: FieldType.select,
    values: prepositionOptions,
    required: false,
    zFieldType: z.enum(["at", "in", "from", "by", "near"]).nullable(),
    description: "Preposition to use when describing this location (e.g., 'at' or 'in')",
  },
  {
    id: "placeType",
    name: "Place Type",
    colspan: 2,
    type: FieldType.select,
    values: placeTypeOptions,
    required: true,
    zFieldType: z.enum(["showroom", "warehouse", "partner", "other"]),
  },
  {
    id: "isGlobal",
    name: "Available to all partners",
    type: FieldType.boolean,
    colspan: 2,
    required: false,
    description: "If checked, this location can be used by any partner",
  },
  {
    id: "isPublic",
    name: "Publicly visible",
    type: FieldType.boolean,
    colspan: 2,
    required: false,
    description: "If checked, this location will be visible in public listings and searches",
  },
  {
    id: "ownerOrgId",
    name: "Owner Organization",
    colspan: 4,
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "description",
    name: "Description",
    colspan: 4,
    type: FieldType.text,
    required: false,
    zFieldType: z.string().nullable(),
    description: "Short description of the location to display on inventory pages",
  },
  {
    id: "tradeInForm",
    name: "Trade-in Form",
    colspan: 4,
    type: FieldType.text,
    required: false,
    zFieldType: z.string().nullable(),
    description: "Link or identifier for a trade-in form specific to this location",
  },
  {
    id: "testRideForm",
    name: "Test Ride Form",
    colspan: 4,
    type: FieldType.text,
    required: false,
    zFieldType: z.string().nullable(),
    description: "Link or identifier for a test ride form specific to this location",
  },
  {
    id: "googlePlaceId",
    name: "Google Maps Place ID",
    colspan: 4,
    type: FieldType.text,
    required: false,
    zFieldType: z.string().nullable(),
    description: "Google Maps Place ID for this location",
  },
];

export const editForm: Form = [...addForm];

export const editInitialValues = {
  ...addInitialValues,
};

export const listColumns: ListColumn[] = [
  {
    id: "name",
    name: "Name",
  },
  {
    id: "postalPlace",
    name: "City",
  },
  {
    id: "placeType",
    name: "Type",
  },
  {
    id: "isGlobal",
    name: "Global",
  },
  {
    id: "isPublic",
    name: "Public",
  },
];

const location: ItemSchema = {
  id: "location",
  name: "Location",
  plural: "Locations",
  listColumns,
  addForm,
  editForm,
  addInitialValues,
  editInitialValues,
  useItemsQuery: useLocationsQuery,
  useItemQuery: useLocationQuery,
  useCreateItemMutation: useCreateLocationMutation,
  useUpdateItemMutation: useUpdateLocationMutation,
  parseItem: (data) => data?.location || {},
  parseItems: (data) => ({
    totalCount: data?.locations?.totalCount || 0,
    items: data?.locations?.nodes || [],
  }),
  createInputFromValues: (values) => ({
    location: values,
  }),
};

export default location;