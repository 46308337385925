import { Badge } from "../atom/badge";
import { Divider } from "../atom/divider";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../atom/dropdown";
import { Link } from "../atom/link";
import { useOrdersQuery, type Order } from "@app/graphql/pim";
import PartnerInvnetoryListHeader from "../molecules/PartnerOrderListHeader";
import {
  DescriptionListInline,
  DescriptionTermInline,
  DescriptionDetailsInline,
} from "../atom/description-list";
import { usePaginationTableState } from "../../hooks/usePaginationTable";
import { orderStatusColors } from "../../utils/colors";
import { useSportiendaOrg } from "../../hooks/org";
import { createImageUrl, formatCurrency } from "../../utils/dom";
import { EllipsisVerticalIcon } from "@heroicons/react/16/solid";
import { useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Pagination from "../molecules/Pagination";
function OrderProperties({
  term,
  details,
}: {
  term?: string;
  details: string | null | undefined;
}) {
  if (!details) {
    return null;
  }
  return (
    <>
      {term && <DescriptionTermInline>{term}:</DescriptionTermInline>}
      <DescriptionDetailsInline>{details}</DescriptionDetailsInline>
    </>
  );
}
export function OrderListingItem({
  order,
  index,
  asHeader,
}: {
  index: number;
  order: Order;
  asHeader?: boolean;
}) {
  const imageid = order?.inventory?.inventoryImages?.nodes?.[0]?.id;
  const imageUrl = imageid
    ? createImageUrl(imageid)
    : "https://sportienda.com/bike.svg";
  const RootElement = asHeader ? "div" : "li";
  return (
    <RootElement>
      {!asHeader && <Divider soft={index > 0} />}
      <div className="flex items-center justify-between">
        <div className="flex gap-6 py-6">
          <div className="w-32 shrink-0">
            <Link href={order.id} aria-hidden="true">
              <img
                className="aspect-[3/2]  dark:bg-gray-100 rounded-lg shadow"
                src={imageUrl}
                alt=""
              />
            </Link>
          </div>
          <div className="space-y-1.5">
            <div className="text-base/6 font-semibold dark:text-gray-50">
              <Link href={order.id}>{order?.inventory?.name}</Link>
            </div>
            <div className="text-xs/6 text-zinc-500">{order?.summary}</div>
            <div className="text-base/6 text-zinc-600">
              {order.total ? formatCurrency(order.total / 100) : ""}
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <Badge
            className="max-sm:hidden"
            color={orderStatusColors[order?.status || "CREATED"]}
          >
            {order.status}
          </Badge>
          {!asHeader && (
            <Dropdown>
              <DropdownButton plain aria-label="More options">
                <EllipsisVerticalIcon />
              </DropdownButton>
              <DropdownMenu anchor="bottom end">
                <DropdownItem href={order.id}>Edit</DropdownItem>
                {/* <DropdownItem>Preview</DropdownItem>
                <DropdownItem>Delete</DropdownItem> */}
              </DropdownMenu>
            </Dropdown>
          )}
        </div>
      </div>
    </RootElement>
  );
}

function PartnerOrderListing({ orgId }: { orgId: string }) {
  const [searchParams] = useSearchParams();
  const STATUS_GROUPS = {
    default: ["PAID"],
    shipped: ["SHIPPED", "DELIVERED"],
    cancelled: ["CANCELLED"],
  };

  const page = searchParams.get("p") || "default";

  //@ts-ignore
  const statusFilter = STATUS_GROUPS[page] || STATUS_GROUPS.default;

  const { filter, offset, orderBy } = usePaginationTableState({
    filters: {
      name: (v: string) => ({ likeInsensitive: `%${v}%` }),
      status: { in: statusFilter },
      orgId: { equalTo: orgId },
    },
  });
  console.log("ORG", orgId, filter);
  let { data, loading } = useOrdersQuery({
    variables: {
      filter,
      pagesize: 20,
      offset,
      orderBy: orderBy as any,
    },
  });

  const orders = data?.orders?.nodes?.map((order) => order as Order);

  return (
    <>
      <PartnerInvnetoryListHeader />

      <ul className="mt-10">
        {orders?.map((order, index) => (
          <OrderListingItem order={order} index={index} key={order.id} />
        ))}
      </ul>
      <Pagination totalCount={data?.orders?.totalCount || 0} pageSize={20} />
      {loading && <div>Loading&hellip;</div>}
    </>
  );
}

export default function PartnerOrderPage() {
  const { org } = useSportiendaOrg();
  if (!org?.id) {
    return null;
  }
  return <PartnerOrderListing orgId={org.id} />;
}
