import React from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Select } from "../atom/select";
import { useLocationOptions, useOrgOptions } from "../LocationSelector";

type FilterDropdownProps = {
  name: string;
  placeholder?: string;
  getOptions: () => { options: { id: string; name: string }[], loading: boolean };
  defaultEmpty?: boolean;
};

export function FilterDropdown({ name, placeholder, getOptions, defaultEmpty = true }: FilterDropdownProps) {
  const { options, loading } = getOptions();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const value = searchParams.get(name) || "";
  
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const id = event.target.value;
    if (!id && defaultEmpty) {
      searchParams.delete(name);
    } else {
      searchParams.set(name, id);
    }
    searchParams.delete("offset");
    setSearchParams(searchParams);
  };
  
  if (loading) {
    return <div className="mr-3 mb-3">Loading...</div>;
  }
  
  const allOptions = [
    { id: "", name: t("All") },
    ...options
  ];
  
  return (
    <div className="mr-3 mb-3">
      <div className="text-xs text-gray-500 mb-1">{placeholder}</div>
      <Select onChange={handleChange} value={value}>
        {allOptions.map((option) => (
          <option key={option.id} value={option.id}>
            {option.name}
          </option>
        ))}
      </Select>
    </div>
  );
}

export function LocationFilterDropdown({ name, placeholder = "Filter by location..." }: { name: string, placeholder?: string }) {
  return <FilterDropdown name={name} placeholder={placeholder} getOptions={useLocationOptions} defaultEmpty={true} />;
}

export function OrgFilterDropdown({ name, placeholder = "Filter by organization..." }: { name: string, placeholder?: string }) {
  return <FilterDropdown name={name} placeholder={placeholder} getOptions={useOrgOptions} defaultEmpty={true} />;
}