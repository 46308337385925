"use client";
import { Avatar } from "../atom/avatar";
import { useSearchParams } from "react-router-dom";
import {
  Dropdown,
  DropdownButton,
  DropdownDivider,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
} from "../atom/dropdown";
import {
  Navbar,
  NavbarDivider,
  NavbarItem,
  NavbarLabel,
  NavbarSection,
  NavbarSpacer,
} from "../atom/navbar";
import {
  ArrowRightStartOnRectangleIcon,
  ChevronDownIcon,
  Cog8ToothIcon,
  LightBulbIcon,
  PlusIcon,
  ShieldCheckIcon,
  UserIcon,
} from "@heroicons/react/16/solid";
import { useState } from "react";
import FilterText from "../molecules/FilterText";
import { InboxIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";

export default function PartnerInventoryListHeader() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showSearch, setShowSearch] = useState(false);
  const page = searchParams.get("p");

  const setPage = (value: string) => {
    setSearchParams({ p: value });
  };

  return (
    <Navbar className="border rounded-md pl-3 pr-2">
      <NavbarLabel className="text-sm font-semibold">Ordre</NavbarLabel>
      <NavbarDivider className="" />
      <NavbarSection className="">
        <NavbarItem
          onClick={() => setPage("default")}
          current={!page || page === "default"}
        >
          Nye
        </NavbarItem>
        <NavbarItem
          onClick={() => setPage("shipped")}
          current={page === "shipped"}
        >
          Sendte
        </NavbarItem>
        <NavbarItem
          onClick={() => setPage("cancelled")}
          current={page === "cancelled"}
        >
          Avbrutt / Returnert
        </NavbarItem>
      </NavbarSection>
      <NavbarSpacer />
      <NavbarSection></NavbarSection>

      {/*
      <InputGroup>
                  <MagnifyingGlassIcon />
                  <FilterText name="name" placeholder="Søk" />
                </InputGroup> */}
    </Navbar>
  );
}
