import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../atom/table";
import { Caption } from "../atom/caption";
import { formatCurrency } from "../../utils/numbers";

type InventoryValueProps = {
  inventoryValues: {
    inventoryType: string | null;
    inventoryCount: any;
    totalValue: any;
    __typename?: string;
  }[];
};

export default function InventoryValue({ inventoryValues }: InventoryValueProps) {
  // Calculate totals across all inventory types
  const totalCount = inventoryValues.reduce(
    (sum, item) => sum + (parseInt(item.inventoryCount) || 0),
    0
  );
  
  const totalValue = inventoryValues.reduce(
    (sum, item) => sum + (parseFloat(item.totalValue) || 0),
    0
  );

  return (
    <>
      <div className="text-lg font-medium text-left mb-2">Lagerbeholdning (sykler)</div>
      <Table>
        <TableHead>
        <TableRow>
          <TableHeader>Type</TableHeader>
          <TableHeader>Antall</TableHeader>
          <TableHeader>Verdi (NOK)</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {inventoryValues.map((item) => (
          <TableRow key={item.inventoryType || 'unknown'}>
            <TableCell>{item.inventoryType || 'Ukjent'}</TableCell>
            <TableCell>{item.inventoryCount || 0}</TableCell>
            <TableCell>{formatCurrency(item.totalValue || 0)}</TableCell>
          </TableRow>
        ))}
        <TableRow className="font-bold">
          <TableCell>Total</TableCell>
          <TableCell>{totalCount}</TableCell>
          <TableCell>{formatCurrency(totalValue)}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
    </>
  );
}