import {
  BicycleType,
  BreakTypes,
  Currency,
  PriceQueryDriveType,
  TypeOfStatus,
  useBicycleQuery,
  useBicyclesQuery,
  useCreateBicycleMutation,
  useReferenceModelsLazyQuery,
  useUpdateBicycleMutation,
} from "@app/graphql/pim";
import {
  FieldType,
  Form,
  ItemFilter,
  ItemSchema,
  ListColumn,
} from "@app/types";
import { z } from "zod";
import { getOptionsFromEnum, stringToNumber } from "../utils/schema";
export const addInitialValues = {
  name: null,
  year: null,
  spokesId: null,
  spokesUrl: null,
  electric: null,
  bicycleType: null,
  breakType: null,
  driveType: null,
  image: null,
  modelId: null,
  price: null,
  currency: "NOK",
  status: "DRAFT",
  tradein: true,
};

// year
//spokesId
//spokesUrl
//electric
//bicycleType
//breakType
//driveType
//image
export const addForm: Form = [
  {
    id: "modelId",
    name: "Model",
    type: FieldType.reference,
    required: true,
    zFieldType: z.string(),
    fieldProps: {
      useGetSearchResultsLazyQuery: useReferenceModelsLazyQuery,
      parseSearchResult: (data: any) => data?.models?.nodes || [],
      parseDefaultName: (data: any) => data?.model?.name || "",
    },
  },
  {
    id: "name",
    name: "Name",
    colspan: 4,
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "year",
    name: "Year",
    type: FieldType.number,
    parseFormData: stringToNumber,
    required: true,
    zFieldType: z.number().min(2000).max(2030),
  },
  {
    id: "price",
    name: "Price",
    type: FieldType.number,
    parseFormData: stringToNumber,
    required: true,
    zFieldType: z.number().int().positive(),
  },
  {
    id: "currency",
    name: "Currency",
    type: FieldType.select,
    values: getOptionsFromEnum(Currency),
    zFieldType: z.nativeEnum(Currency),
    required: true,
  },
  {
    id: "spokesId",
    name: "Spokes ID",
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "spokesUrl",
    name: "Spokes URL",
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "electric",
    name: "Electric",
    type: FieldType.boolean,
    required: true,
    zFieldType: z.boolean(),
  },
  {
    id: "bicycleType",
    name: "Bicycle Type",
    type: FieldType.select,
    values: getOptionsFromEnum(BicycleType),
    zFieldType: z.nativeEnum(BicycleType),
    required: true,
  },
  {
    id: "breakType",
    name: "Break Type",
    type: FieldType.select,
    values: getOptionsFromEnum(BreakTypes),
    zFieldType: z.nativeEnum(BreakTypes),
    required: true,
  },
  {
    id: "driveType",
    name: "Drive Type",
    type: FieldType.select,
    values: getOptionsFromEnum(PriceQueryDriveType),
    zFieldType: z.nativeEnum(PriceQueryDriveType),
    required: true,
  },
  {
    id: "image",
    name: "Image",
    type: FieldType.text,
    required: true,
    zFieldType: z.string(),
  },
  {
    id: "status",
    name: "Status",
    type: FieldType.select,
    values: getOptionsFromEnum(TypeOfStatus),
    zFieldType: z.nativeEnum(TypeOfStatus),
    required: true,
  },
  {
    id: "tradein",
    name: "Available for Trade-in",
    type: FieldType.boolean,
    required: true,
    zFieldType: z.boolean(),
  },
];

export const editForm: Form = [...addForm];

export const editInitialValues = {
  ...addInitialValues,
};

export const listColumns: ListColumn[] = [
  {
    id: "id",
    name: "ID",
  },

  {
    id: "name",
    name: "Name",
  },
  {
    id: "status",
    name: "Status",
  },
  {
    id: "spokesId",
    name: "Spokes ID",
  },
  {
    id: "model",
    name: "Model",
    parseFormData: (data) => data?.name,
  },
];

const filters: ItemFilter[] = [
  {
    name: "name",
    type: "text",
    subtype: "like",
  },
  {
    name: "status",
    type: "enum",
    enum: TypeOfStatus,
  },
];

const bicycle: ItemSchema = {
  id: "bicycle",
  name: "Bicycle",
  plural: "Bicycles",
  idIsNumber: true,
  listColumns,
  addForm,
  filters,
  editForm,
  addInitialValues,
  editInitialValues,
  useItemsQuery: useBicyclesQuery,
  useItemQuery: useBicycleQuery,
  useCreateItemMutation: useCreateBicycleMutation,
  useUpdateItemMutation: useUpdateBicycleMutation,
  parseItem: (data) => data?.bicycle || {},
  parseItems: (data) => ({
    totalCount: data?.bicycles?.totalCount,
    items: data?.bicycles?.nodes,
  }),
  createInputFromValues: (values) => ({
    bicycle: values,
  }),
};

export default bicycle;
