import { useQuery } from "@apollo/client";
import { useState, useMemo } from "react";
import { Heading } from "../../../components/atom/heading";
import { StackedContent } from "../../../components/atom/content";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/atom/table";
import Loading from "../../../components/molecules/Loading";
import Error from "../../../components/molecules/Error";
import { formatDate } from "../../../utils/dates";
import { SearchAnalyticsDocument } from "@app/graphql/pim";

const timeRanges = [
  { label: "Last 24 hours", value: "24h" },
  { label: "Last 7 days", value: "7d" },
  { label: "Last 30 days", value: "30d" },
  { label: "Last 90 days", value: "90d" },
  { label: "All time", value: "all" },
];

export default function SearchAnalytics() {
  const [timeRange, setTimeRange] = useState("30d");

  // Use useMemo to prevent recalculation on every render
  const dateRange = useMemo(() => {
    const toDate = new Date().toISOString();
    let fromDate: string;
    
    switch (timeRange) {
      case "24h":
        fromDate = new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString();
        break;
      case "7d":
        fromDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString();
        break;
      case "30d":
        fromDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString();
        break;
      case "90d":
        fromDate = new Date(Date.now() - 90 * 24 * 60 * 60 * 1000).toISOString();
        break;
      case "all":
        fromDate = new Date(0).toISOString(); // Beginning of time
        break;
      default:
        fromDate = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toISOString();
    }
    
    return { fromDate, toDate };
  }, [timeRange]); // Only recalculate when timeRange changes

  const { data, loading, error } = useQuery(SearchAnalyticsDocument, {
    variables: {
      fromDate: dateRange.fromDate,
      toDate: dateRange.toDate,
    },
    fetchPolicy: "cache-and-network", // Use cached data where available
    onError: (error) => {
      console.error("Error fetching search analytics:", error);
    }
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  const searchAnalytics = data?.getSearchAnalytics?.nodes || [];

  return (
    <StackedContent>
      <Heading>Search Analytics</Heading>
      
      <div className="mb-4">
        <label htmlFor="time-range" className="block text-sm font-medium text-gray-700 mb-1">
          Time Range
        </label>
        <select
          id="time-range"
          value={timeRange}
          onChange={(e) => setTimeRange(e.target.value)}
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        >
          {timeRanges.map((range) => (
            <option key={range.value} value={range.value}>
              {range.label}
            </option>
          ))}
        </select>
      </div>

      {searchAnalytics.length === 0 ? (
        <div className="text-center py-4 text-gray-500">
          No search data available for the selected time period.
        </div>
      ) : (
        <>
          <div className="my-2 text-sm text-gray-500">
            Showing {searchAnalytics.length} search terms from {formatDate(dateRange.fromDate)} to {formatDate(dateRange.toDate)}.
          </div>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeader className="w-1/3">Search Term</TableHeader>
                <TableHeader className="w-1/6">Count</TableHeader>
                <TableHeader className="w-1/6">Avg Results</TableHeader>
                <TableHeader className="w-1/6">Category</TableHeader>
                <TableHeader className="w-1/6">Subcategory</TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {searchAnalytics.map((item: any, index: number) => (
                <TableRow key={index}>
                  <TableCell>{item.searchTerm}</TableCell>
                  <TableCell>{item.count}</TableCell>
                  <TableCell>{item.avgResults !== null ? item.avgResults : "-"}</TableCell>
                  <TableCell>{item.category || "-"}</TableCell>
                  <TableCell>{item.subcategory || "-"}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
    </StackedContent>
  );
}