import { classNames, formatCurrency, createImageUrl } from "../../utils/dom";
import { useOrdersQuery, OrderStatus, OrdersOrderBy } from "@app/graphql/pim";
import { Fragment } from "react";
import { useSportiendaOrg } from "../../hooks/org";
import ShippingForm from "../../schemata/componets/order/ShippingForm";
import PartnerInventoryAnalytics from "../molecules/PartnerInventoryAnalytics";
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from "@headlessui/react";
import {
  ArrowDownCircleIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  Bars3Icon,
  EllipsisHorizontalIcon,
  PlusSmallIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
const statuses: any = {
  Paid: "text-green-700 bg-green-50 ring-green-600/20",
  Withdraw: "text-gray-600 bg-gray-50 ring-gray-500/10",
  Overdue: "text-red-700 bg-red-50 ring-red-600/10",
};
type Transaction = {
  id: number;
  invoiceNumber: string;
  href: string;
  amount: string;
  inventoryName?: string;
  inventoryImage?: string;
  status: string;
  client: string;
  description: string;
  createdAt: string;
  shippingMethod: string;
  shippingTime?: string;
};

type Day = {
  date: string;
  transactions: Transaction[];
};

export default function PartnerDashboard() {
  const { org } = useSportiendaOrg();
  const filter = {
    status: { equalTo: OrderStatus.Paid },
    orgId: { equalTo: org?.id },
  };
  const days: Day[] = [];

  const { data: orderData, refetch: orderRefetch } = useOrdersQuery({
    variables: { filter, orderBy: OrdersOrderBy.CreatedDesc },
  });
  const orders = orderData?.orders?.nodes || [];
  if (orders?.length > 0) {
    console.log("orderStatus", orderData);
    const unshippedOrders: Day = {
      date: "Nye",
      transactions: [],
    };
    days.push(unshippedOrders);
    orders.forEach((order) => {
      console.log(order);
      const inventoryImageId = order.inventory?.inventoryImages?.nodes?.[0]?.id;
      const inventoryImageURL = createImageUrl(inventoryImageId);
      const transaction: Transaction = {
        id: order.id,
        invoiceNumber: order.id.toString(),
        href: "#",
        amount: order.total ? formatCurrency(order.total / 100) : "",
        inventoryName: order.inventory?.name || "",
        inventoryImage: inventoryImageURL || "",
        status: order.status,
        client: order.shippingFirstName + " " + order.shippingLastName,
        description: order.summary || "",
        createdAt: order.created
          ? new Date(order.created).toLocaleString()
          : "",
        shippingMethod: order.shippingMethod || "",
      };
      unshippedOrders.transactions.push(transaction);
    });
  }

  const { data: recentShippedOrderData, refetch: recentShippedOrderRefetch } =
    useOrdersQuery({
      variables: {
        filter: {
          status: { equalTo: OrderStatus.Shipped },
          shippingTime: { isNull: false },
          orgId: { equalTo: org?.id },
        },
        orderBy: OrdersOrderBy.ShippingTimeDesc,
        pagesize: 5,
      },
    });

  const shippedOrders = recentShippedOrderData?.orders?.nodes || [];
  if (shippedOrders.length > 0) {
    const shippedOrdersDay: Day = {
      date: "Sist sendt",
      transactions: [],
    };
    days.push(shippedOrdersDay);
    shippedOrders.forEach((order) => {
      const inventoryImageId = order.inventory?.inventoryImages?.nodes?.[0]?.id;
      const inventoryImageURL = createImageUrl(inventoryImageId);
      const transaction: Transaction = {
        id: order.id,
        invoiceNumber: order.id.toString(),
        href: "#",
        amount: order.total ? formatCurrency(order.total / 100) : "",
        inventoryName: order.inventory?.name || "",
        inventoryImage: inventoryImageURL || "",
        status: order.status,
        client: order.shippingFirstName + " " + order.shippingLastName,
        description: order.summary || "",
        createdAt: order.created
          ? new Date(order.created).toLocaleString()
          : "",
        shippingMethod: order.shippingMethod || "",
        shippingTime: order.shippingTime
          ? new Date(order.shippingTime).toLocaleString()
          : "",
      };
      shippedOrdersDay.transactions.push(transaction);
    });
  }

  const refetch = () => {
    orderRefetch();
    recentShippedOrderRefetch();
  };

  if (!org || !org.id) {
    return null;
  }
  return (
    <>
      <PartnerInventoryAnalytics />
      <div className="space-y-16 py-16 xl:space-y-20">
        {/* Recent activity table */}
        <div>
          <div className="">
            <h2 className=" text-base font-semibold text-gray-900">
              Siste salg
            </h2>
          </div>
          <div className="mt-6 overflow-hidden border-t border-gray-100">
            <div className="">
              <div className="mx-auto">
                <table className="w-full text-left">
                  <thead className="sr-only">
                    <tr>
                      <th>Total</th>
                      <th className="hidden sm:table-cell">Ordre</th>
                      <th>Detaljer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {days.map((day) => (
                      <Fragment key={day.date}>
                        <tr className="text-sm/6 text-gray-900">
                          <th
                            scope="colgroup"
                            colSpan={3}
                            className="relative isolate py-2 font-semibold"
                          >
                            {day.date}
                            <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                            <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                          </th>
                        </tr>
                        {day.transactions.map((transaction) => (
                          <tr key={transaction.id}>
                            <td className="relative py-5 pr-6">
                              <div className="flex items-center gap-x-3">
                                <div className="text-xs/6  text-gray-500">
                                  {transaction.createdAt}
                                </div>
                              </div>
                              <div className="flex gap-x-6">
                                <div className="flex-auto">
                                  <div className="flex items-start gap-x-3">
                                    <div className="text-sm/6 font-medium text-gray-900">
                                      {transaction.amount}
                                    </div>
                                  </div>
                                </div>{" "}
                              </div>
                              <div className="text-xs/5 text-gray-900">
                                {transaction.shippingMethod}
                              </div>
                              {transaction.shippingTime && (
                                <div className="text-xs/5 text-gray-900">
                                  {transaction.shippingTime}
                                </div>
                              )}

                              <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                              <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                            </td>

                            <td className="py-5 pr-6 table-cell">
                              <div className="flex items-center gap-x-3">
                                <div className="flex items-center gap-x-3">
                                  <img
                                    src={transaction.inventoryImage}
                                    alt=""
                                    className="w-16 h-16 rounded-lg object-cover"
                                  />
                                </div>
                                <div>
                                  <div className="text-sm/6 text-gray-900">
                                    {transaction.inventoryName}
                                  </div>
                                  <div className="mt-1 text-xs/5 text-gray-500">
                                    {transaction.description}
                                  </div>
                                  <div className="mt-1 text-xs/5 text-gray-500">
                                    {transaction.client}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="py-5 text-right">
                              <div className="flex justify-end">
                                <Menu as="div" className="relative ml-auto">
                                  <MenuButton className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                                    <span className="sr-only">
                                      Open options
                                    </span>
                                    <EllipsisHorizontalIcon
                                      aria-hidden="true"
                                      className="size-5"
                                    />
                                  </MenuButton>
                                  <MenuItems
                                    transition
                                    className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                                  >
                                    <MenuItem>
                                      <Link
                                        to={`/order/${transaction.id}`}
                                        className="block px-3 py-1 text-sm/6 text-gray-900 data-[focus]:bg-gray-50 data-[focus]:outline-none"
                                      >
                                        Detaljer
                                      </Link>
                                    </MenuItem>
                                    {transaction.status === "PAID" && (
                                      <MenuItem>
                                        <ShippingForm
                                          data={transaction}
                                          id="shipping"
                                          name="Utlever / send"
                                          dialog={true}
                                          refetch={refetch}
                                          description="Send varen til kunden"
                                        />
                                      </MenuItem>
                                    )}
                                  </MenuItems>
                                </Menu>
                              </div>
                              <div className="mt-1 text-xs/5 text-gray-500"></div>
                            </td>
                          </tr>
                        ))}
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
