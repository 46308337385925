import type { Option } from "@app/types";
import { Combobox, Listbox, RadioGroup, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect, useState } from "react";
import { classNames } from "../../utils/dom";
import * as ButtonBase from "../atom/button";
import * as CheckBase from "../atom/checkbox";
import * as InputBase from "../atom/input";
import { Fieldset, Field, Label, Description } from "../atom/fieldset";
import { Textarea } from "../atom/textarea";
import { Select } from "../atom/select";
import * as TextBase from "../atom/text";
import { ImageUpload } from "../../components/organisms/ImageUpload";
import { LocationSelector } from "../LocationSelector";

export function Input({
  type,
  name,
  autoComplete,
  placeholder,
  parseFormData,
  children,
  required,
  value,
  onChange,
}: {
  type: string;
  name: string;
  autoComplete?: string;
  placeholder?: string;
  parseFormData?: (data: any) => any;
  children: string;
  required?: boolean;
  value?: any;
  onChange: (field: string, value: any) => void;
}) {
  const update = (e: any) => {
    onChange(
      name,
      parseFormData ? parseFormData(e.target.value) : e.target.value
    );
  };
  return (
    <Field>
      <Label htmlFor={name}>{children}</Label>
      <InputBase.Input
        type={type}
        name={name}
        id={name}
        value={value}
        onChange={update}
        required={required}
        placeholder={placeholder}
        autoComplete={autoComplete}
      />
    </Field>
  );
}

export function TextAreaInput({
  name,
  children,
  value,
  onChange,
}: {
  name: string;
  children: string;
  value: string;
  onChange: (field: string, value: any) => void;
}) {
  const update = (e: any) => {
    onChange(name, e.target.value);
  };

  return (
    <Field>
      <Label htmlFor={name}>{children}</Label>
      <Textarea name={name} id={name} value={value} onChange={update} />
    </Field>
  );
}

export function Text({
  children,
  value,
  parseFormData,
}: {
  children: string;
  value: string;
  parseFormData?: (data: any) => any;
}) {
  return (
    <Field>
      <Label>{children}</Label>
      <TextBase.Text>
        {parseFormData ? parseFormData(value) : value}
      </TextBase.Text>
    </Field>
  );
}

export function BooleanInput({
  name,
  children,
  description,
  checked,
  onChange,
}: {
  name: string;
  children: string;
  description?: string;
  checked: boolean;
  onChange: (field: string, value: any) => void;
}) {
  const update = (value: boolean) => {
    onChange(name, value);
  };
  return (
    <CheckBase.CheckboxField>
      <CheckBase.Checkbox
        name={name}
        checked={checked}
        onChange={update}
        children={children}
      />
      <Label>{children}</Label>
      <Description>{description}</Description>
    </CheckBase.CheckboxField>
  );
}

export function ImageInput({
  name,
  itemId,
  children,
  description,
  value,
  required,
  onChange,
  fieldProps,
}: {
  name: string;
  children: React.ReactNode;
  description?: string;
  value?: any;
  required?: boolean;
  itemId: string | null;
  onChange: (field: string, value: any) => void;
  fieldProps?: any;
}) {
  const [uploadImage] = fieldProps.useUploadImageMutation();
  const [deleteImage] = fieldProps.useDeleteImageMutation();
  const [loading, setLoading] = useState(false);
  const update = async (images: [any]) => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);

      for (const img of images) {
        try {
          const {
            data: {
              uploadImage: { uploadURL },
            },
          } = await uploadImage({
            variables: {
              id: itemId,
              forType: fieldProps.forType,
            },
          });

          if (uploadURL) {
            let formData = new FormData();
            formData.append("file", img.file);
            await fetch(uploadURL, {
              method: "POST",
              body: formData,
            });
          } else {
            alert("Failed to upload. No upload URL found.");
          }
        } catch (e) {
          // TODO: Error message
          console.error(e);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const remove = async (value: any) => {
    await deleteImage({
      variables: {
        id: itemId,
        forType: fieldProps.forType,
      },
    });
  };

  return (
    <Field>
      <Label>{children}</Label>
      {description && <Description>{description}</Description>}
      <ImageUpload
        imageIds={[value]}
        onRemoved={remove}
        multiple={false}
        required={required}
        onChange={update}
      />
    </Field>
  );
}

export function ReferenceInput({
  name,
  children,
  description,
  value,
  required,
  extraData,
  onChange,
  fieldProps,
}: {
  name: string;
  children: React.ReactNode;
  description?: string;
  value?: any;
  required?: boolean;
  extraData?: any;
  onChange: (field: string, value: any) => void;
  fieldProps?: any;
}) {
  const [getSearchResults, { data }] =
    fieldProps.useGetSearchResultsLazyQuery();

  const [query, setQuery] = useState("");
  const [selected, setSelected] = useState({
    id: value,
    name: fieldProps.parseDefaultName(extraData),
  });

  const [searchResults, setSearchResults] = useState<any>([]);

  useEffect(() => {
    if (data) {
      setSearchResults(fieldProps.parseSearchResult(data));
    }
  }, [data]);
  useEffect(() => {
    if (query.length < 3) {
      setSearchResults([]);
      return;
    }
    getSearchResults({
      variables: {
        query: `%${query}%`,
      },
    });
  }, [query]);
  useEffect(() => {
    onChange(name, selected.id);
  }, [selected]);

  return (
    <Field>
      <Label>{children}</Label>
      {description && <Description>{description}</Description>}

      <Combobox as="div" value={selected} onChange={setSelected as any}>
        <div className="relative mt-2">
          <Combobox.Input
            className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(event) => setQuery(event.target.value)}
            displayValue={(item: any) => item?.name}
          />
          <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
            <ChevronUpDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </Combobox.Button>

          {searchResults.length > 0 && (
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {searchResults.map((item: any) => (
                <Combobox.Option
                  key={item.id}
                  value={item}
                  className={({ active }) =>
                    classNames(
                      "relative cursor-default select-none py-2 pl-3 pr-9",
                      active ? "bg-indigo-600 text-white" : "text-gray-900"
                    )
                  }
                >
                  {({ active, selected }) => (
                    <>
                      <span
                        className={classNames(
                          "block truncate",
                          selected && "font-semibold"
                        )}
                      >
                        {item.name}
                      </span>

                      {selected && (
                        <span
                          className={classNames(
                            "absolute inset-y-0 right-0 flex items-center pr-4",
                            active ? "text-white" : "text-indigo-600"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>
    </Field>
  );
}

export function OptionsInput({
  name,
  description,
  value,
  children,
  required,
  options = [],
  nullable,
  onChange,
}: {
  name: string;
  description?: string;
  value?: any;
  required?: boolean;
  children: React.ReactNode;
  options: Option[] | undefined;
  nullable?: boolean;
  onChange: (field: string, value: any) => void;
}) {
  if (!options) {
    return null;
  }
  const allOptions = nullable
    ? [{ id: null, name: "Ingen" }, ...options]
    : value
    ? options
    : [{ id: undefined, name: "Velg", disabled: true }, ...options];

  if (!nullable && value === null) {
    value = undefined;
  }
  const update = (value: any) => {
    onChange(name, value);
  };

  return (
    <Field>
      <Label>{children}</Label>
      {description && <Description>{description}</Description>}
      <Select value={value} onChange={(e) => update(e.target.value)}>
        {allOptions.map((option) => (
          <option
            key={option.id}
            value={option.id || undefined}
            hidden={option.disabled}
          >
            {option.name}
          </option>
        ))}
      </Select>
    </Field>
  );
}

export function LocationSelectorInput({
  name,
  children,
  description,
  value,
  required,
  extraData,
  onChange,
}: {
  name: string;
  children: React.ReactNode;
  description?: string;
  value?: any;
  required?: boolean;
  extraData?: any;
  onChange: (field: string, value: any) => void;
}) {
  const update = (value: any) => {
    onChange(name, value);
  };

  return (
    <Field>
      <Label>{children}</Label>
      {description && <Description>{description}</Description>}
      <LocationSelector 
        value={value}
        onChange={update}
        name={children ? String(children) : name}
        soldByOrgId={extraData?.soldByOrgId}
      />
    </Field>
  );
}
