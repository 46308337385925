import type { FormFieldComponentProps, Form as FormType } from "@app/types";
import { FieldType } from "@app/types";
import { useState } from "react";
import { getTranslation } from "../../utils/schema";
import { Button } from "../atom/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "../atom/dialog";
import {
  BooleanInput,
  ImageInput,
  Input,
  LocationSelectorInput,
  OptionsInput,
  ReferenceInput,
  Text,
} from "./CMSInputs";
import { Fieldset, FieldGroup, Legend } from "../atom/fieldset";

type FormProps = {
  form: FormType;
  data: any;
  title: string;
  dictionary: any;
  loading: boolean;
  itemId: string | null;
  refetch?: () => void;
  save?: (data: any) => void;
  actionName?: string;
  extraData?: any;
};

type FormCoreProps = FormProps & {
  update: (field: string, value: any) => void;
};

function FormCore({
  form,
  data,
  title,
  dictionary,
  loading,
  extraData,
  refetch,
  save,
  itemId,
  actionName,
  update,
}: FormCoreProps) {
  const formData = data || {};
  return (
    <>
      {form?.map(
        ({
          id,
          name,
          type,
          values,
          required,
          colspan,
          parseFormData,
          nullable,
          autocomplete,
          description,
          fieldProps,
          info,
        }) => {
          if (type === FieldType.text) {
            return (
              <Input
                key={name}
                type="text"
                name={id}
                value={formData[id]}
                required={required}
                autoComplete={autocomplete}
                parseFormData={parseFormData}
                onChange={update}
              >
                {getTranslation(dictionary, name)}
              </Input>
            );
          } else if (type === FieldType.number) {
            return (
              <Input
                key={name}
                type="number"
                name={id}
                value={formData[id]}
                required={required}
                autoComplete={autocomplete}
                parseFormData={parseFormData}
                onChange={update}
              >
                {getTranslation(dictionary, name)}
              </Input>
            );
          } else if (type === FieldType.email) {
            return (
              <Input
                key={name}
                type="email"
                name={id}
                value={formData[id]}
                required={required}
                autoComplete={autocomplete}
                parseFormData={parseFormData}
                onChange={update}
              >
                {getTranslation(dictionary, name)}
              </Input>
            );
          } else if (type === FieldType.phone) {
            return (
              <Input
                key={name}
                type="tel"
                name={id}
                value={formData[id]}
                required={required}
                autoComplete={autocomplete}
                parseFormData={parseFormData}
                onChange={update}
              >
                {getTranslation(dictionary, name)}
              </Input>
            );
          } else if (type === FieldType.datetime) {
            return (
              <Input
                key={name}
                type="datetime-local"
                name={id}
                value={formData[id]}
                required={required}
                autoComplete={autocomplete}
                parseFormData={parseFormData}
                onChange={update}
              >
                {getTranslation(dictionary, name)}
              </Input>
            );
          } else if (type === FieldType.boolean) {
            return (
              <BooleanInput
                key={name}
                name={id}
                checked={formData[id]}
                description={description}
                onChange={update}
              >
                {getTranslation(dictionary, name)}
              </BooleanInput>
            );
          } else if (type === FieldType.select) {
            return (
              <OptionsInput
                key={name}
                name={id}
                value={formData[id]}
                required={required}
                options={values}
                nullable={nullable}
                onChange={update}
              >
                {getTranslation(dictionary, name)}
              </OptionsInput>
            );
          } else if (type === FieldType.image) {
            return (
              <ImageInput
                key={name}
                name={id}
                value={extraData ? extraData[id] : null}
                required={required}
                onChange={update}
                itemId={itemId}
                fieldProps={fieldProps}
              >
                {getTranslation(dictionary, name)}
              </ImageInput>
            );
          } else if (type === FieldType.reference) {
            return (
              <ReferenceInput
                key={name}
                name={id}
                value={formData[id]}
                extraData={extraData}
                required={required}
                fieldProps={fieldProps}
                onChange={update}
              >
                {getTranslation(dictionary, name)}
              </ReferenceInput>
            );
          } else if (type === FieldType.label) {
            return (
              <Text
                key={name}
                value={extraData[id]}
                parseFormData={parseFormData}
              >
                {getTranslation(dictionary, name)}
              </Text>
            );
          } else if (type === FieldType.component) {
            const Component =
              fieldProps?.component as React.ComponentType<FormFieldComponentProps>;
            return (
              <Component
                key={name}
                id={id}
                name={getTranslation(dictionary, name)}
                data={extraData}
                refetch={refetch}
              />
            );
          } else if (type === FieldType.custom) {
            if (fieldProps?.component === "LocationSelector") {
              return (
                <LocationSelectorInput
                  key={name}
                  name={id}
                  value={formData[id]}
                  extraData={extraData}
                  required={required}
                  onChange={update}
                >
                  {getTranslation(dictionary, name)}
                </LocationSelectorInput>
              );
            }
            return null;
          } else {
            throw Error(`Unknown field type ${type}`);
          }
        }
      )}
    </>
  );
}

export default function Form({
  itemId,
  form,
  data,
  title,
  dictionary,
  loading,
  extraData,
  refetch,
  save,
  actionName,
}: FormProps) {
  const [formData, setFormData] = useState(data);
  const update = (field: string, value: any) => {
    setFormData((prev: any) => ({ ...prev, [field]: value }));
  };

  return (
    <Fieldset>
      <Legend>{title}</Legend>
      <FieldGroup>
        <FormCore
          form={form}
          data={formData}
          title={title}
          dictionary={dictionary}
          loading={loading}
          extraData={extraData}
          refetch={refetch}
          save={save}
          actionName={actionName}
          update={update}
          itemId={itemId}
        />
        {save && (
          <button
            onClick={() => save(formData)}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sportgreen hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sportgreen disabled:opacity-50"
            disabled={loading}
          >
            {actionName || "Save"}
          </button>
        )}
      </FieldGroup>
    </Fieldset>
  );
}

export type DialogFormProps = FormProps & {
  description?: string;
};

export function DialogForm({
  itemId,
  form,
  data,
  title,
  dictionary,
  loading,
  extraData,
  refetch,
  save,
  actionName,
  description,
}: DialogFormProps) {
  const [isOpen, setIsOpen] = useState(false);

  const [formData, setFormData] = useState(data);
  const update = (field: string, value: any) => {
    setFormData((prev: any) => ({ ...prev, [field]: value }));
  };

  const saveAction = () => {
    if (save) {
      save(formData);
      setIsOpen(false);
    } else {
      alert("Save function is not defined");
      setIsOpen(false);
    }
  };

  return (
    <>
      <Button type="button" onClick={() => setIsOpen(true)}>
        {title}
      </Button>
      <Dialog open={isOpen} onClose={setIsOpen}>
        <DialogTitle>{title}</DialogTitle>
        {description && <DialogDescription>{description}</DialogDescription>}
        <DialogBody>
          <FormCore
            form={form}
            data={formData}
            title={title}
            dictionary={dictionary}
            loading={loading}
            extraData={extraData}
            refetch={refetch}
            save={save}
            actionName={actionName}
            update={update}
            itemId={itemId}
          />
        </DialogBody>
        <DialogActions>
          <Button plain onClick={() => setIsOpen(false)}>
            Avbryt
          </Button>
          <Button onClick={() => saveAction()}>{actionName || "Lagre"}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const FormWrapper = ({ children }: { children: any }) => {
  return <>{children}</>;
};
