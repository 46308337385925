import {
  Navbar,
  NavbarItem,
  NavbarSection,
  NavbarSpacer,
  NavbarLabel,
} from "../atom/navbar";
import UserDropdown from "./UserDropdown";
import { parterSidebarNavigation } from "./Sidebar";
export default function SportiendaNavbar() {
  return (
    <Navbar>
      <NavbarSpacer />
      <NavbarSection>
        {parterSidebarNavigation.map((item) => (
          <NavbarItem key={item.name} href={`/${item.href}`}>
            <item.icon />
            <NavbarLabel>{item.name}</NavbarLabel>
          </NavbarItem>
        ))}
      </NavbarSection>
    </Navbar>
  );
}
