import { ApolloError } from "@apollo/client";
import { XCircleIcon } from "@heroicons/react/24/solid";

interface ErrorProps {
  error: ApolloError | Error | string;
  title?: string;
}

export default function Error({ error, title = "Error" }: ErrorProps) {
  let errorMessage = "";
  
  if (typeof error === "string") {
    errorMessage = error;
  } else if (error instanceof ApolloError) {
    // Handle Apollo errors (GraphQL errors or network errors)
    if (error.graphQLErrors && error.graphQLErrors.length > 0) {
      errorMessage = error.graphQLErrors.map(e => e.message).join('\n');
    } else if (error.networkError) {
      errorMessage = `Network error: ${error.networkError.message}`;
    } else {
      errorMessage = error.message;
    }
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }

  return (
    <div className="rounded-md bg-red-50 p-4 my-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-800">{title}</h3>
          <div className="mt-2 text-sm text-red-700">
            <p className="whitespace-pre-line">{errorMessage}</p>
          </div>
        </div>
      </div>
    </div>
  );
}