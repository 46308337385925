import { format } from 'date-fns'
import { nb } from 'date-fns/locale'

export function dateFormat(date, scheme = 'dd.MM.yy') {
  return format(new Date(Date.parse(date)), scheme, { locale: nb })
}

export function dateTimeFormat(date, scheme = 'dd.MM.yy HH:mm:ss') {
  try{
   return format(new Date(Date.parse(date)), scheme, { locale: nb })
  }
  catch{
    return ""
  }
}

export function formatDate(date) {
  if (!date) return '';
  try {
    return format(new Date(date), 'dd.MM.yyyy', { locale: nb });
  } catch {
    return '';
  }
}
